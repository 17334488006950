import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import { passwordMinLength } from 'octiv-utilities/Constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, initialValues, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        userIds: initialValues.userIds,
        password: undefined,
      }}
      validationSchema={Yup.object().shape({
        userIds: Yup.array().required(t('required')).min(1, t('required')),
        password: Yup.string()
          .required(t('required'))
          .min(passwordMinLength, t('passwordsMustBeAtLeast8Characters')),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field
                helper={t('passwordsMustBeAtLeast8Characters')}
                label={t('newPassword')}
                name='password'
              />
            </Col>

            <Col>
              <Button isLoading={isLoading} text={t('submit')} type='submit' />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
