import { Container, Modal, ToggleTabs } from 'octiv-components';
import { usePaging, useToggle } from 'octiv-hooks';
import { useLocationsFind } from 'octiv-hooks/requests/Locations';
import { useTenantsFind } from 'octiv-hooks/requests/Tenants';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Contact from './contact';
import FormQuery from './FormQuery';
import Packages from './packages';
import TableTenantLocations from './TableTenantLocations';

export const views = {
  CONTACT: 'contact',
  PACKAGES: 'packages',
};

const DiscoveryDashboard = () => {
  const { t } = useTranslation();

  const [paging, setPaging, resetPaging] = usePaging();
  const [toggle, setToggle, resetToggle] = useToggle();
  const [selectedModalView, setSelectedModalView] = useState(views.CONTACT);
  const [query, setQuery] = useState({
    regionId: 1,
    isActive: 1,
    search: '',
    tenantId: undefined,
  });

  const tenantsFind = useTenantsFind(
    {
      filter: { statusId: 1 },
      paging: { perPage: -1 },
    },
    {
      enabled: false,
    }
  );

  const locations = useLocationsFind({
    filter: query,
    paging,
    include: 'tenant,addresses,tenant.headCoaches',
  });

  const handleSetQuery = (values) => {
    if (values?.isActive !== query?.isActive) {
      resetPaging();
    }
    setQuery(values);
  };

  return (
    <>
      {toggle.isVisible && (
        <Modal
          renderAboveContent={() => (
            <ToggleTabs
              activeKey={selectedModalView}
              mt={1}
              options={[
                { label: t('contact'), key: views.CONTACT },
                { label: t('packages'), key: views.PACKAGES },
              ]}
              px={4}
              setActiveKey={setSelectedModalView}
            />
          )}
          title={`Location: ${toggle?.data?.name}`}
          onClose={resetToggle}
        >
          {selectedModalView === views.CONTACT ? (
            <Contact location={toggle?.data} />
          ) : (
            <Packages locationId={toggle?.data?.id} />
          )}
        </Modal>
      )}
      <Container
        appBarProps={{
          title: t('dashboard'),
        }}
        isLoading={false}
      >
        <FormQuery
          initialValues={query}
          isFetchingTenants={tenantsFind.isFetching}
          tenants={tenantsFind.data?.data}
          onSubmit={handleSetQuery}
        />

        <br />
        <br />
        <br />

        <TableTenantLocations
          data={locations?.isFetching ? [] : locations?.data?.data || []}
          isLoading={locations?.isFetching}
          paging={locations?.data?.paging}
          setPaging={setPaging}
          onClickRow={({ original }) => {
            setToggle({
              type: views.CONTACT,
              data: original,
              isVisible: true,
            });
          }}
        />
      </Container>
    </>
  );
};

export default DiscoveryDashboard;
