import { Alert, Box, Modal } from 'octiv-components';
import { useToggle } from 'octiv-hooks';
import {
  useAccessControlCreateSystemGroupAddUsers,
  useAccessControlCreateSystemGroupRemoveUsers,
  useAccessControlFindByIdSystemUsers,
  useAccessControlFindSystemGroups,
  useAccessControlGetUsersInAGroup,
} from 'octiv-hooks/requests/AccessControl';
import { useAcsEvents } from 'octiv-hooks/useAcsEvents';
import { AcsEvent } from 'octiv-utilities/Constants';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormAcsPackages from './FormAcsPackages';
import FormQueryUsers from './FormQueryUsers';
import Table from './Table';
import TableUsers from './TableUsers';

const AccessSystemUserGroups = ({ acsId, locationId }) => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [groupUsers, setGroupUsers] = useState([]);
  const [toggle, setToggle, resetToggle] = useToggle();
  const [packageToggle, setPackageToggle, resetPackageToggle] = useToggle();
  const [manageGroupId, setManageGroupId] = useState(undefined);
  const [manageGroupIdPackages, setManageGroupIdPackages] = useState(undefined);
  const [loadingIds, setLoadingIds] = useState(new Set());
  const [isNoGroupsAlert, setIsNoGroupsAlert] = useState(false);

  const [querySystemUsers, setQuerySystemUsers] = useState({
    search: undefined,
  });

  const apiFindUsers = useAccessControlFindByIdSystemUsers(
    {
      enabled: toggle.isVisible,
      filter: { id: acsId, locationId },
    },
    { onSuccess: (response) => setUsers(response || []) }
  );

  const apiFindAllGroups = useAccessControlFindSystemGroups(
    {
      filter: { id: acsId },
    },
    {
      onSuccess: (response) => {
        if (response.length === 0) setIsNoGroupsAlert(true);
      },
    }
  );

  const apiGetUsersInAGroup = useAccessControlGetUsersInAGroup(
    {
      filter: { id: acsId, acsGroupId: manageGroupId },
    },
    {
      onSuccess: (response) => {
        setGroupUsers(response || []);
      },
    }
  );

  const apiAddUserToGroup = useAccessControlCreateSystemGroupAddUsers({
    meta: { useOnSuccessToast: true },
  });

  const apiRemoveUserFromGroup = useAccessControlCreateSystemGroupRemoveUsers({
    meta: { useOnSuccessToast: true },
  });

  const handleUserAddedToGroup = (event) => {
    const { user } = event;
    setLoadingIds((prev) => {
      const newSet = new Set(prev);
      newSet.delete(user.id);
      return newSet;
    });
    setGroupUsers((prevUsers) => {
      if (!prevUsers.some((prevUser) => prevUser.id === user.id)) {
        return [...prevUsers, user];
      }
      return prevUsers;
    });
  };

  const handleUserRemovedFromGroup = (event) => {
    const { user } = event;
    setLoadingIds((prev) => {
      const newSet = new Set(prev);
      newSet.delete(user.id);
      return newSet;
    });
    setGroupUsers((prevUsers) =>
      prevUsers.filter((prevUser) => prevUser.id !== user.id)
    );
  };

  useAcsEvents(
    locationId,
    [AcsEvent.USER_ADDED_TO_GROUP],
    handleUserAddedToGroup
  );
  useAcsEvents(
    locationId,
    [AcsEvent.USER_REMOVED_FROM_GROUP],
    handleUserRemovedFromGroup
  );

  useEffect(() => {
    if (apiFindUsers?.data) {
      const searchQuery = querySystemUsers.search?.toLowerCase() || '';

      const filtered = apiFindUsers.data.filter((user) => {
        const matchesSearch = user.name?.toLowerCase().includes(searchQuery);

        return matchesSearch;
      });

      setUsers(filtered);
    } else {
      setUsers([]);
    }
  }, [querySystemUsers.search, apiFindUsers?.data]);

  const tableData = useMemo(() => {
    const groupUserIds = new Set(groupUsers.map((user) => user.id));
    return users.map((user) => ({
      ...user,
      isUserInGroup: groupUserIds.has(user.id),
    }));
  }, [users, groupUsers]);

  const handleActionClick = ({ action, id }) => {
    setLoadingIds((prev) => {
      const newSet = new Set(prev);
      newSet.add(id);
      return newSet;
    });

    switch (action) {
      case 'manageUsers':
        setManageGroupId(id);
        setToggle({ isVisible: true });
        break;
      case 'automatedAccess':
        setManageGroupIdPackages(id);
        setPackageToggle({ isVisible: true });
        break;
      case 'add':
        apiAddUserToGroup.mutate({
          acsUserIds: id,
          acsGroupId: manageGroupId,
          id: acsId,
        });
        break;
      case 'remove':
        apiRemoveUserFromGroup.mutate({
          acsUserIds: id,
          acsGroupId: manageGroupId,
          id: acsId,
        });
        break;
      default:
        setLoadingIds((prev) => {
          const newSet = new Set(prev);
          newSet.delete(id);
          return newSet;
        });
        break;
    }
  };

  return (
    <>
      <Box hasRadiusBottom bg='grey6' p={4}>
        {toggle.isVisible && (
          <Modal isSidebar title={t('manageUsers')} onClose={resetToggle}>
            <Box mb={4}>
              <FormQueryUsers
                initialValues={querySystemUsers}
                onSubmit={setQuerySystemUsers}
              />
            </Box>
            <TableUsers
              hasAlternateBackground
              data={tableData}
              isLoading={
                apiFindUsers.isFetching || apiGetUsersInAGroup.isFetching
              }
              loadingIds={loadingIds}
              mt={4}
              onClickAction={handleActionClick}
            />
          </Modal>
        )}
        {packageToggle.isVisible && (
          <Modal
            isSidebar
            title={t('automatedAccess')}
            onClose={resetPackageToggle}
          >
            <FormAcsPackages
              acsId={acsId}
              locationId={locationId}
              manageGroupIdPackages={manageGroupIdPackages}
            />
          </Modal>
        )}

        <Table
          hasAlternateBackground
          data={apiFindAllGroups.data}
          isLoading={apiFindAllGroups.isFetching}
          onClickAction={handleActionClick}
        />
      </Box>

      {isNoGroupsAlert && (
        <Alert
          m={6}
          p={2}
          subtitle={t('noUserGroups')}
          title={t('pleaseNote')}
          variant='warning'
        />
      )}
    </>
  );
};

export default AccessSystemUserGroups;
