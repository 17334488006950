import { ToggleTabs } from 'octiv-components';
import Container from 'octiv-components/container/index';
// import { useQuery } from 'octiv-hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';

// import AccessSystemLogs from './logs';
import AccessSystemUserGroups from './accessGroups';
// import AccessSystemCredentials from './credentials';
import AccessSystemDetails from './details';
import AccessSystemEntrances from './entrances';
import AccessSystemUsers from './users';

export default () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const locationId = Number(searchParams.get('locationId'));
  const { id: acsId } = useParams();

  return (
    <Container
      appBarProps={{
        title: t('system'),
        breadcrumbs: [t('accessControl')],
      }}
    >
      {!!acsId && (
        <ToggleTabs
          hasRadiusTop
          bg='grey6'
          mt={4}
          options={[
            // {
            //   label: t('logs'),
            //   children: (
            //     <AccessSystemLogs acsId={acsId} locationId={locationId} />
            //   ),
            // },
            {
              label: t('details'),
              children: (
                <AccessSystemDetails
                  acsId={acsId}
                  originalLocationId={locationId}
                />
              ),
            },
            ...(locationId
              ? [
                  {
                    label: t('users'),
                    children: (
                      <AccessSystemUsers
                        acsId={acsId}
                        locationId={locationId}
                      />
                    ),
                  },
                  {
                    label: t('accessGroups'),
                    children: (
                      <AccessSystemUserGroups
                        acsId={acsId}
                        locationId={locationId}
                      />
                    ),
                  },
                  {
                    label: t('entrances'),
                    children: (
                      <AccessSystemEntrances
                        acsId={acsId}
                        locationId={locationId}
                      />
                    ),
                  },
                  // {
                  //   label: t('credentials'),
                  //   children: (
                  //     <AccessSystemCredentials
                  //       acsId={acsId}
                  //       locationId={locationId}
                  //     />
                  //   ),
                  // },
                ]
              : []),
          ]}
          px={4}
        />
      )}
    </Container>
  );
};
