import { Box, Button, ProcessingSpinner, Table } from 'octiv-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, loadingIds, ...props }) => {
  const { t } = useTranslation();

  const tableColumns = useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'name',
      },
      {
        Header: (
          <Box
            isFlex
            alignItems='center'
            justifyContent='center'
            maxWidth='50px'
            minWidth='50px'
          >
            {t('action')}
          </Box>
        ),
        accessor: 'id',
        Cell: ({ row: { original } }) => (
          <Box
            isFlex
            alignItems='center'
            justifyContent='center'
            maxWidth='50px'
            minWidth='50px'
          >
            {loadingIds.has(original.id) ? (
              <ProcessingSpinner mt={2} />
            ) : original.isUserInGroup ? (
              <Button
                color='danger'
                scale='small'
                text={t('remove')}
                onClick={() =>
                  onClickAction({ action: 'remove', id: original.id })
                }
              />
            ) : (
              <Button
                color='success'
                scale='small'
                text={t('add')}
                onClick={() =>
                  onClickAction({ action: 'add', id: original.id })
                }
              />
            )}
          </Box>
        ),
      },
    ],
    [onClickAction, loadingIds, t]
  );

  return <Table columns={tableColumns} data={data} {...props} />;
};
