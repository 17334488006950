import { Table, Text, Tooltip } from 'octiv-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, loggedInUserTypeId, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'name',
      },
      {
        Header: t('code'),
        accessor: 'code',
        Cell: ({ row: { original } }) => {
          return original.isMultiPhoneSyncCredential ? (
            t('Mobile lock')
          ) : (
            <Text color='success'>{original.code}</Text>
          );
        },
      },

      {
        Header: t('status'),
        accessor: 'IsLatestDesiredStateSyncedWithProvider',
        Cell: ({ row: { original } }) =>
          original.warnings.some(({ message }) => message?.includes('Deleting'))
            ? t('removing')
            : original.isLatestDesiredStateSyncedWithProvider ||
              original.isMultiPhoneSyncCredential
            ? t('Synced')
            : t('Syncing...'),
      },
      {
        Header: t('actions'),
        accessor: 'id',
        Cell: ({ row: { original } }) => {
          return (
            <Tooltip
              stopPropagation
              options={[{ label: t('delete'), value: 'delete' }]}
              onClick={({ value }) =>
                onClickAction({
                  action: value,
                  id: original.id,
                })
              }
            />
          );
        },
      },
    ],
    [t, onClickAction]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
