import { Table, Text, Tooltip } from 'octiv-components';
import { AccessContext } from 'octiv-context';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useMemberModal } from 'octiv-context/MemberModal';
import {
  canSetMemberModal,
  getDateReadableDayMonthYear,
  getFullName,
  toCase,
  toggleAllRowsSelected,
  toggleRowSelected,
} from 'octiv-utilities/Functions';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, setSelected, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const { setMemberModal } = useMemberModal();
  const {
    tenant: { isSepa },
    toCurrency,
  } = useActiveUserTenant();
  const hasAccess = useContext(AccessContext);

  // Set a row to selected
  const setRowSelected = ({ id }) => {
    setSelected(toggleRowSelected({ data, id }));
  };

  // Set all rows selected
  const setAllRowsSelected = ({ isAllSelected }) => {
    setSelected(toggleAllRowsSelected({ data, isAllSelected }));
  };

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('code'),
        accessor: 'code',
        sortBy: 'code',
      },
      {
        Header: t('member'),
        // TODO: lookup user name in included user model
        accessor: 'user',
        sortBy: 'user_name',
        Cell: ({
          row: {
            original: { user, leadMember, nonMember },
          },
        }) => (
          <Text
            as='span'
            onClick={
              canSetMemberModal({ userTenant: user?.userTenant, hasAccess })
                ? (e) => {
                    e.stopPropagation();
                    setMemberModal({ userTenantId: user?.userTenant?.id });
                  }
                : null
            }
          >
            {getFullName(user || leadMember || nonMember)}
          </Text>
        ),
      },
      {
        Header: t('description'),
        accessor: 'description',
        sortBy: 'description',
        whiteSpaceWrap: true,
      },
      {
        Header: t('status'),
        accessor: 'status',
        sortBy: 'status',
        Cell: ({ value }) => (
          <Text
            as='span'
            color={
              value === 'pending' || value === 'submitted'
                ? 'warning'
                : value === 'credited'
                ? 'info'
                : value === 'paid'
                ? 'success'
                : value === 'unpaid'
                ? 'danger'
                : undefined
            }
          >
            {t(value)}
          </Text>
        ),
      },
      {
        Header: t('dueDate'),
        accessor: 'dueOn',
        sortBy: 'due_on',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
      {
        Header: t('sentDate'),
        accessor: 'sentAt',
        sortBy: 'sent_on',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
      {
        Header: t('paymentRequestSentDate'),
        accessor: 'paymentRequestSentAt',
        sortBy: 'payment_request_sent_at',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
      {
        Header: t('paymentTypes'),
        id: 'paymentTypes',
        accessor: 'payments',
        Cell: ({ value }) =>
          value
            ? [
                ...new Set(
                  value?.map((payment) => toCase({ value: t(payment.type) })) ||
                    []
                ),
              ].join(', ')
            : '',
      },
      {
        Header: t('paymentProcessors'),
        id: 'paymentTags',
        accessor: 'payments',
        Cell: ({ value }) =>
          value
            ? [
                ...new Set(
                  ...(value?.map((payment) =>
                    payment?.tags?.map((tag) => tag.name)
                  ) || [])
                ),
              ].join(', ')
            : '',
      },
      {
        Header: t('outstanding'),
        id: 'outstanding',
        Cell: ({
          row: {
            original: { outstandingAmount },
          },
        }) =>
          toCurrency({
            value: outstandingAmount,
          }),
      },
      {
        Header: t('amount'),
        accessor: 'amount',
        sortBy: 'amount',
        Cell: ({ value }) => {
          return toCurrency({ value: Number(value) });
        },
      },
      {
        Header: t('actions'),
        id: 'actions',
        Cell: ({
          row: {
            original: { id, status },
          },
        }) => (
          <Tooltip
            stopPropagation
            options={[
              { label: t('view'), value: 'view' },
              { label: t('recordPayment'), value: 'record' },
              { label: t('requestProcessPayment'), value: 'request' },
              ...(status !== 'paid'
                ? [
                    ...(isSepa
                      ? [
                          {
                            label: t('addToDebitBatch'),
                            value: 'addToDebitBatch',
                          },
                        ]
                      : []),
                    { label: t('edit'), value: 'edit' },
                    { label: t('delete'), value: 'delete' },
                  ]
                : []),
              { label: t('download'), value: 'download' },
              { label: t('send'), value: 'send' },
            ]}
            placement='left'
            onClick={({ value }) => onClickAction({ action: value, id })}
          />
        ),
      },
    ],
    [onClickAction, toCurrency, setMemberModal, hasAccess, t, isSepa]
  );

  const hiddenColumns = useMemo(
    () => (hasAccess.featureAccountsInvoiceActions ? [] : ['actions']),
    [hasAccess.featureAccountsInvoiceActions]
  );

  const sortBy = useMemo(
    () => [
      {
        id: 'dueOn',
        desc: true,
      },
    ],
    []
  );

  return (
    <Table
      {...props}
      columns={tableColumns}
      data={tableData}
      hiddenColumns={hiddenColumns}
      paging={props.paging}
      setPaging={props.setPaging}
      sortBy={sortBy}
      onSelect={setRowSelected}
      onSelectAll={setAllRowsSelected}
    />
  );
};
