import { Table, Text } from 'octiv-components';
import { AccessContext } from 'octiv-context';
import { useMemberModal } from 'octiv-context/MemberModal';
import {
  canSetMemberModal,
  getDateReadableDayMonthYear,
  getFullName,
} from 'octiv-utilities/Functions';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const hasAccess = useContext(AccessContext);
  const { setMemberModal } = useMemberModal();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('member'),
        accessor: 'user',
        Cell: ({ value }) => (
          <Text
            as='span'
            onClick={
              canSetMemberModal({ userTenant: value?.userTenant, hasAccess })
                ? () => {
                    setMemberModal({ userTenantId: value?.userTenant?.id });
                  }
                : null
            }
          >
            {getFullName(value)}
          </Text>
        ),
      },
      {
        Header: t('packages'),
        accessor: 'userPackages',
        whiteSpaceWrap: true,
        Cell: ({ value }) =>
          value
            ? value
                .map(
                  (item) =>
                    `${item.package.name} (${item.sessionsAvailableText})`
                )
                .join(', ')
            : '',
      },
      {
        Header: t('lastAttended'),
        accessor: 'lastAttendedOn',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
    ],
    [hasAccess, setMemberModal, t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
