import { Alert } from 'octiv-components';
import {
  useAccessControlFindSystemPackages,
  useAccessControlGetPackageIdsInAGroup,
  useAccessControlUpdateSystemPackages,
} from 'octiv-hooks/requests/AccessControl';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Form from './Form';
import FormPackagesInGroup from './FormPackagesInGroup';

export default ({ manageGroupIdPackages, locationId, acsId }) => {
  const [tenantPackages, setTenantPackages] = useState([]);
  const [packageIdsInGroup, setPackageIdsInGroup] = useState([]);
  const [formValues, setFormValues] = useState({});
  const { t } = useTranslation();

  const apiGetPackagesForATenant = useAccessControlFindSystemPackages(
    {
      filter: { locationId },
      paging: { perPage: -1 },
    },
    {
      onSuccess: (response) => {
        setTenantPackages(response?.data || []);
      },
    }
  );

  const apiGetPackageIdsInAGroup = useAccessControlGetPackageIdsInAGroup(
    {
      filter: { id: acsId, acsGroupId: manageGroupIdPackages },
    },
    {
      onSuccess: (response) => {
        setPackageIdsInGroup(response?.settings?.packageIds || []);
      },
    }
  );

  const {
    isLoading: isUpdatingGroupsPackages,
    mutate: apiUpdateAcsGroupPackages,
  } = useAccessControlUpdateSystemPackages({
    meta: { useOnSuccessToast: true },
  });

  return (
    <>
      <Alert mb={4} subtitle={t('automatedAccessInfoText')} variant='warning' />

      {!apiGetPackageIdsInAGroup.isFetching && (
        <Form
          initialValues={apiGetPackageIdsInAGroup.data}
          onValuesChange={setFormValues}
        />
      )}

      <FormPackagesInGroup
        existingPackageIdsInGroup={packageIdsInGroup}
        isFetchingPackagesForATenant={
          apiGetPackageIdsInAGroup.isFetching ||
          apiGetPackagesForATenant.isFetching
        }
        isLoading={isUpdatingGroupsPackages}
        tenantPackages={formValues.isDefaultForMembers ? [] : tenantPackages}
        onSubmit={(values) =>
          apiUpdateAcsGroupPackages({
            id: acsId,
            acsGroupId: manageGroupIdPackages,
            isDefaultForStaff: formValues.isDefaultForStaff ? 1 : 0,
            isDefaultForMembers: formValues.isDefaultForMembers ? 1 : 0,
            ...values,
          })
        }
      />
    </>
  );
};
