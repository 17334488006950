import { Table, Tooltip } from 'octiv-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, loggedInUserTypeId, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('account'),
        accessor: 'account.name',
      },
      {
        Header: t('name'),
        accessor: 'name',
      },
      {
        Header: t('location'),
        accessor: 'location',
      },
      {
        Header: t('type'),
        accessor: 'type.name',
      },
      {
        Header: t('actions'),
        accessor: 'id',
        Cell: ({ row: { original } }) => {
          return (
            <Tooltip
              stopPropagation
              options={[{ label: t('viewEdit'), value: 'viewEdit' }]}
              onClick={({ value }) => {
                onClickAction({
                  action: value,
                  id: original.id,
                  locationId: original.locationId,
                });
              }}
            />
          );
        },
      },
    ],
    [onClickAction, t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
