import { Box, Chip, Table } from 'octiv-components';
import { getFullName } from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';

export default ({
  data,
  isSwitchingUnlinking,
  signedInUserId,
  onSwitch,
  onUnlink,
  ...props
}) => {
  const tableData = useMemo(() => data, [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: 'Name',
        id: 'name',
        accessor: 'linkedUser',
        Cell: ({ value }) => getFullName(value),
      },
      {
        id: 'actions',
        Cell: ({ row: { original } }) => {
          const isDisabled =
            isSwitchingUnlinking || signedInUserId === original.linkedUser?.id;

          return (
            <Box isFlex>
              <Box ml='auto'>
                <Chip
                  icon='login'
                  isDisabled={isDisabled}
                  mr={2}
                  title='Login'
                  onClick={
                    isDisabled
                      ? null
                      : () => onSwitch({ id: original.linkedUser.id })
                  }
                />

                <Chip
                  color='warning'
                  icon='link_off'
                  isDisabled={isDisabled}
                  title='Unlink'
                  onClick={
                    isDisabled ? null : () => onUnlink({ id: original.id })
                  }
                />
              </Box>
            </Box>
          );
        },
      },
    ],
    [isSwitchingUnlinking, onSwitch, onUnlink, signedInUserId]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
