import { Box, Chip, ConfirmationDialog, Modal } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useSignedInUser } from 'octiv-context/SignedInUser';
import { usePaging, useToggle } from 'octiv-hooks';
import {
  useAccessControlCreateSystemsByIdUsersLink,
  useAccessControlCreateUsersSuspend,
  useAccessControlCreateUsersUnsuspend,
  useAccessControlDeleteSystemUsers,
  useAccessControlFindByIdSystemUsers,
  useAccessControlFindSystemPackages,
  useUserListFind,
} from 'octiv-hooks/requests/AccessControl';
import { useAcsEvents } from 'octiv-hooks/useAcsEvents';
import { AcsEvent } from 'octiv-utilities/Constants';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormQuery from './common/FormQuery';
import FormQueryUsers from './FormQueryUsers';
import ModalImportUsers from './import/ModalImportUsers';
import ModalManageUser from './manage/ModalManageUser';
import Table from './Table';
import TableUsers from './TableUsers';

export default ({ locationId, acsId }) => {
  const [users, setUsers] = useState([]);
  const [paging, setPaging] = usePaging();
  const [manageUserId, setManageUserId] = useState(null);
  const [manageUserName, setManageUserName] = useState(null);
  const [removingUsers, setRemovingUsers] = useState([]);
  const [packages, setPackages] = useState([]);
  const { t } = useTranslation();
  const [toggle, setToggle, resetToggle] = useToggle();
  const [toggleUser, setToggleUser, resetToggleUser] = useToggle();
  const [toggleImportUsers, setToggleImportUsers, resetToggleImportUsers] =
    useToggle();
  const [filteredData, setFilteredData] = useState([]);

  const {
    tenant: { locationOptionsAll },
  } = useActiveUserTenant();

  const { userTenants } = useSignedInUser();
  const limitInterFacilityBookings =
    userTenants?.[0]?.tenant?.limitInterFacilityBookings;

  const [query, setQuery] = useState({
    userType: 'member',
    search: undefined,
    sort: 'name',
    userTenantPackageId: undefined,
    ...(!limitInterFacilityBookings && { locationId }),
  });

  const [querySystemUsers, setQuerySystemUsers] = useState({
    search: undefined,
    userLinkedStatus: 'linked',
  });

  const [confirmationDialog, setConfirmationDialog] = useState({
    isDanger: false,
    confirmText: null,
    open: false,
    title: '',
    message: '',
    onConfirm: null,
  });

  const packagesFind = useAccessControlFindSystemPackages(
    {
      filter: { locationId },
      paging: { perPage: -1 },
    },
    {
      onSuccess: (response) => setPackages(response?.data),
    }
  );

  const usersFind = useUserListFind(
    {
      filter: {
        systemId: acsId,
        statusId: 2,
        userTenantPackageId: query.userTenantPackageId,
        userTenantStatusId: '1,2',
        userTenantLocationId: query.locationId || locationId,
        search: query.search,
        ...(query.userType === 'staff'
          ? { userTenantTypeId: '2,3,5,6,8' }
          : query.userType === 'member'
          ? { userTenantTypeId: '4' }
          : { userTenantTypeId: '9' }), // Leads
      },
      enabled: !!toggleImportUsers.isVisible,
      paging,
    },
    {
      onSuccess: (response) => setUsers(response?.data),
    }
  );

  const apiFind = useAccessControlFindByIdSystemUsers(
    {
      filter: {
        locationId,
        id: acsId,
        status: '1,2', // active, pending
      },
    },
    {}
  );

  const apiDelete = useAccessControlDeleteSystemUsers({
    meta: { useOnSuccessToast: true },
  });

  const apiSuspend = useAccessControlCreateUsersSuspend({
    meta: { useOnSuccessToast: true },
  });

  const apiUnsuspend = useAccessControlCreateUsersUnsuspend({
    meta: { useOnSuccessToast: true },
  });

  const apiLinkUser = useAccessControlCreateSystemsByIdUsersLink({
    meta: { useOnSuccessToast: true },
  });

  useAcsEvents(
    locationId,
    [
      AcsEvent.USER_ADDED,
      AcsEvent.USER_DELETED,
      AcsEvent.USER_SUSPENDED,
      AcsEvent.USER_UNSUSPENDED,
    ],
    () => {
      apiFind.refetch();
    }
  );

  useEffect(() => {
    if (apiFind?.data) {
      const searchQuery = querySystemUsers.search?.toLowerCase() || '';
      const linkedStatus = querySystemUsers.userLinkedStatus;

      const filtered = apiFind.data.filter((user) => {
        const matchesSearch = user.name?.toLowerCase().includes(searchQuery);
        const matchesLinkedStatus =
          linkedStatus === 'linked'
            ? user.seamIdentityId !== null
            : linkedStatus === 'notLinked'
            ? user.seamIdentityId === null
            : true;

        return matchesSearch && matchesLinkedStatus;
      });

      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [
    querySystemUsers.search,
    querySystemUsers.userLinkedStatus,
    apiFind?.data,
  ]);

  const handleDialogClose = () => {
    setConfirmationDialog({ ...confirmationDialog, open: false });
  };

  const onClickAction = ({ action, id, name }) => {
    switch (action) {
      case 'manage':
        setManageUserId(id);
        setManageUserName(name);
        setToggleUser({
          isVisible: true,
          userName: name,
          userId: id,
        });
        break;

      case 'suspend':
        setConfirmationDialog({
          open: true,
          title: t('suspendUserConfirmation'),
          onConfirm: () => {
            apiSuspend.mutate({ id: acsId, acsUserIds: id });
            handleDialogClose();
          },
        });
        break;

      case 'unsuspend':
        setConfirmationDialog({
          open: true,
          title: t('unsuspendUserConfirmation'),
          onConfirm: () => {
            apiUnsuspend.mutate({ id: acsId, acsUserIds: id });
            handleDialogClose();
          },
        });
        break;

      case 'delete':
        setConfirmationDialog({
          isDanger: true,
          confirmText: t('delete'),
          open: true,
          title: t('deleteUserConfirmation'),
          onConfirm: () => {
            setRemovingUsers((prev) => [...prev, id]);
            apiDelete.mutate({ id: acsId, acsUserIds: [id] });
            handleDialogClose();
          },
        });
        break;

      case 'link':
        setToggle({ data: id, type: 'link' });
        break;

      case 'confirmLink':
        apiLinkUser.mutate({
          acsUserIds: [toggle.data],
          userId: id,
          id: acsId,
        });
        resetToggle();
        break;

      default:
        break;
    }
  };

  return (
    <Box hasRadiusBottom bg='grey6' p={4}>
      <ConfirmationDialog
        confirmText={confirmationDialog.confirmText}
        isDanger={confirmationDialog.isDanger}
        open={confirmationDialog.open}
        title={confirmationDialog.title}
        onCancel={handleDialogClose}
        onConfirm={confirmationDialog.onConfirm}
      />

      {toggleImportUsers.isVisible && (
        <ModalImportUsers acsId={acsId} onHideModal={resetToggleImportUsers} />
      )}
      {toggle.isVisible ? (
        <Modal isSidebar title={t('linkOctivUser')} onClose={resetToggle}>
          <FormQuery
            initialValues={query}
            isFetchingPackages={packagesFind.isFetching}
            limitInterFacilityBookings={limitInterFacilityBookings}
            locationOptionsAll={locationOptionsAll}
            packages={packages}
            onSubmit={setQuery}
          />
          <Box mb={4} />
          <TableUsers
            hasAlternateBackground
            data={users}
            isLoading={usersFind.isFetching}
            paging={usersFind?.data?.paging}
            setPaging={setPaging}
            setSelected={setUsers}
            onClickAction={onClickAction}
          />
        </Modal>
      ) : null}

      {toggleUser.isVisible ? (
        <ModalManageUser
          acsId={acsId}
          manageUserId={manageUserId}
          manageUserName={manageUserName}
          onClose={resetToggleUser}
        />
      ) : null}
      <Box
        alignItems='center'
        display='flex'
        flexDirection={['column', 'row']}
        justifyContent='space-between'
        mb={5}
      >
        <Box width={500}>
          <FormQueryUsers
            initialValues={querySystemUsers}
            onSubmit={setQuerySystemUsers}
          />
        </Box>
        <Chip
          hasAlternateBackground
          icon='add'
          my={2}
          py={5}
          title={t('importUsers')}
          onClick={setToggleImportUsers}
        />
      </Box>
      <Table
        hasAlternateBackground
        data={filteredData}
        isLoading={apiFind?.isFetching}
        removingUsers={removingUsers}
        onClickAction={onClickAction}
      />
    </Box>
  );
};
