import { Table } from 'octiv-components';
import {
  getFullName,
  toggleAllRowsSelected,
  toggleRowSelected,
} from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ hasAlternateBackground, data, setSelected, ...props }) => {
  const { t } = useTranslation();

  // Set a row to selected
  const setRowSelected = ({ id }) => {
    setSelected(toggleRowSelected({ data, id }));
  };

  // Set all rows selected
  const setAllRowsSelected = ({ isAllSelected }) => {
    setSelected(toggleAllRowsSelected({ data, isAllSelected }));
  };

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'name',
        Cell: ({ row: { original } }) => getFullName(original),
      },
    ],
    [t]
  );

  return (
    <Table
      hasAlternateBackground
      {...props}
      columns={tableColumns}
      data={tableData}
      onSelect={setRowSelected}
      onSelectAll={setAllRowsSelected}
    />
  );
};
