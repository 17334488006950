import Container from 'octiv-components/container/index';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useAccessControlFindSystems } from 'octiv-hooks/requests/AccessControl';
import { filterLocationsWithAccessControl } from 'octiv-utilities/Functions';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import AcsAccounts from './accounts/index';
import TableAccessSystems from './TableAccessSystems';

export default () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    selectedLocation,
    tenant: { locationOptionsAll },
    tenant: { locations },
  } = useActiveUserTenant();

  const locationOptionsWithAccessControl = filterLocationsWithAccessControl(
    locations,
    true
  );

  const [query, setQuery] = useState({
    locationId: selectedLocation?.id,
  });

  const accessControlFindSystems = useAccessControlFindSystems({
    filter: query,
  });

  const locationMap = useMemo(() => {
    const map = {};
    locationOptionsAll.forEach((location) => {
      if (location.value) {
        map[location.value] = location.label;
      }
    });
    return map;
  }, [locationOptionsAll]);

  const processedData = useMemo(() => {
    return (accessControlFindSystems?.data?.data || []).map((system) => {
      const { locationId } = system;
      return {
        ...system,
        location: locationMap[locationId] || t('notAssigned'),
      };
    });
  }, [accessControlFindSystems?.data?.data, locationMap, t]);

  const onClickAction = ({ action, id, locationId }) => {
    switch (action) {
      case 'viewEdit':
      default:
        navigate(`/access-control/systems/${id}?locationId=${locationId}`);
    }
  };

  return (
    <>
      <AcsAccounts
        locationOptionsAll={locationOptionsWithAccessControl}
        query={query}
        setQuery={setQuery}
      />
      <Container
        appBarProps={{
          title: t('systems'),
          breadcrumbs: [t('accessControl')],
        }}
      >
        <br />
        <TableAccessSystems
          data={processedData}
          isLoading={accessControlFindSystems.isFetching}
          onClickAction={onClickAction}
        />
      </Container>
    </>
  );
};
