import { Formik } from 'formik';
import { Button, Field } from 'octiv-components';
import { passwordMinLength } from 'octiv-utilities/Constants';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

const AuthResetPasswordForm = ({ isLoading, ...props }) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Formik
      {...props}
      initialValues={{
        password: undefined,
        passwordConfirmation: undefined,
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .required(t('required'))
          .min(passwordMinLength, t('passwordsMustBeAtLeast8Characters')),
        passwordConfirmation: Yup.string()
          .oneOf([Yup.ref('password'), null], t('passwordsMustMatch'))
          .required(t('required')),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field
            autoComplete
            boxContainerProps={{ mb: 4 }}
            helper={t('passwordsMustBeAtLeast8Characters')}
            iconRightProps={{
              name: showPassword ? 'visibility_off' : 'visibility',
            }}
            label={t('newPassword')}
            name='password'
            textHelperProps={{ mb: 4 }}
            type={showPassword ? 'text' : 'password'}
            onClickIconRight={() => setShowPassword(!showPassword)}
          />

          <Field
            autoComplete
            boxContainerProps={{ mb: 4 }}
            label={t('confirmNewPassword')}
            name='passwordConfirmation'
            type={showPassword ? 'text' : 'password'}
          />

          <Button
            isFullWidth
            isLoading={isLoading}
            text={t('submit')}
            type='submit'
          />
        </form>
      )}
    </Formik>
  );
};

export default AuthResetPasswordForm;
