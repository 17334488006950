import { Box, Button, Step } from 'octiv-components';
import { useAccessControlFindSystemGroups } from 'octiv-hooks/requests/AccessControl';
import { getSelectedRows } from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import TableACSGroups from './TableACSGroups';

export default ({ acsId, onBack, onSubmit }) => {
  const [groups, setGroups] = useState([]);

  const { t } = useTranslation();

  // Fetch selected rows
  const selectedData = getSelectedRows({
    data: groups?.length > 0 ? groups : [],
  });

  const apiFind = useAccessControlFindSystemGroups(
    {
      filter: {
        id: acsId,
      },
    },
    {
      onSuccess: (response) => {
        setGroups(response || []);
      },
    }
  );

  return (
    <>
      <Step
        boxContainerProps={{ mb: 4 }}
        mb={2}
        title={t('selectUserGroupAccessControl')}
        value='2'
      />
      <TableACSGroups
        hasAlternateBackground
        data={groups}
        isLoading={apiFind?.isFetching}
        setSelected={setGroups}
      />
      <Box isFlex flexDirection='row' mt={2}>
        <Button
          mr={3}
          text={t('next')}
          onClick={() => onSubmit(selectedData.map((group) => group.id))}
        />
        <Button hasBorder text={t('back')} onClick={onBack} />
      </Box>
    </>
  );
};
