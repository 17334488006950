import { Table, Tooltip } from 'octiv-components';
import {
  getDateReadableDayMonthYear,
  getFullName,
} from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, loggedInUserTypeId, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'name',
        Cell: ({ row: { original } }) => getFullName(original),
      },
      {
        Header: t('created'),
        accessor: 'createdAt',
        sortBy: 'createdAt',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
      {
        Header: t('actions'),
        accessor: 'id',
        Cell: ({ row: { original } }) => {
          return (
            <Tooltip
              stopPropagation
              options={[
                { label: t('manageUsers'), value: 'manageUsers' },
                { label: t('automatedAccess'), value: 'automatedAccess' },
              ]}
              onClick={({ value }) =>
                onClickAction({
                  action: value,
                  id: original.id,
                })
              }
            />
          );
        },
      },
    ],
    [onClickAction, t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
