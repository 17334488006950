import _ from 'lodash';
import {
  Box,
  Button,
  Col,
  Container,
  Divider,
  Icon,
  Image,
  Modal,
  Row,
  Text,
} from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useToggle } from 'octiv-hooks';
import {
  useStoreCreateSales,
  useStoreFindStockItems,
} from 'octiv-hooks/requests/Store';
import { useUsersFindMembers } from 'octiv-hooks/requests/Users';
import { useUserTenantsFindByIdPaymentDetails } from 'octiv-hooks/requests/UserTenants';
import { isValidUrl } from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import FormCart from './FormCart';
import FormQuery from './FormQuery';
import TableCart from './TableCart';

export default () => {
  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();

  const {
    selectedLocation,
    tenant: { locationOptions },
    toCurrency,
  } = useActiveUserTenant();

  const [query, setQuery] = useState({
    locationId: selectedLocation?.id,
    search: undefined,
  });

  const [formCustomerType, setFormCustomerType] = useState('member');
  const [toggle, setToggle, resetToggle] = useToggle();
  const [products, setProducts] = useState([]);
  const [userIdForPayment, setUserIdForPayment] = useState(undefined);
  const [userTenantIdForPayment, setUserTenantIdForPayment] =
    useState(undefined);
  const [cart, setCart] = useState([]);

  const storeFindStockItems = useStoreFindStockItems(
    {
      filter: { locationId: query.locationId, search: query.search },
      paging: { perPage: -1 },
    },
    {
      onSuccess: (res) => setProducts(res.data),
    }
  );

  const usersFind = useUsersFindMembers({
    filter: {
      userTenantLocationId: query.locationId,
    },
    paging: { perPage: -1 },
    sort: 'name',
  });

  const storeCreateSale = useStoreCreateSales({
    meta: {
      useOnSuccessToast: true,
    },
  });

  const {
    data: userPaymentDetails,
    isFetching: isFetchingUserPaymentDetails,
    // refetch: getUserPaymentDetailsRequest,
    // TODO:
  } = useUserTenantsFindByIdPaymentDetails(
    {
      id: formCustomerType === 'member' ? userTenantIdForPayment : undefined,
    },
    {
      enabled: !!userTenantIdForPayment && formCustomerType === 'member',
    }
  );

  const onGetUserPaymentDetailsRequest = (id) => {
    const userTenant = usersFind?.data?.data?.find(
      (user) => user.id === id
    )?.userTenant;

    setUserIdForPayment(id);
    setUserTenantIdForPayment(userTenant?.id);
  };

  const onSubmitFormCart = (obj) => {
    const {
      customerType,
      userTenantId,
      name,
      email,
      mobile,
      note,
      salePaymentType,
      discount,
    } = obj;

    setFormCustomerType(customerType);

    storeCreateSale.mutate(
      {
        note,
        salePaymentType,
        discount,
        userId: customerType === 'member' ? userIdForPayment : undefined,
        ...(customerType === 'member'
          ? { userTenantId }
          : { nonMember: { name, email, mobile } }),
        locationId: query.locationId,
        products: cart.map((item) => ({
          id: item.id,
          quantity: item.quantity,
        })),
      },
      {
        onSuccess: ({ code }) => {
          if (salePaymentType === 'adhocPayment') {
            navigate(`/accounts/invoices?requestPaymentCode=${code}`);
          } else {
            resetToggle();
            setCart([]);
          }
        },
      }
    );
  };

  const updateCart = ({ add, subtract, product }) => {
    const newCart = [...cart];
    const newProducts = [...(storeFindStockItems.data?.data || [])];

    const cartIndex = newCart.findIndex((item) => item.id === product.id);
    const productIndex = newProducts.findIndex(
      (item) => item.id === product.id
    );

    const { stockLevel } = newProducts[productIndex];
    const hasUnlimitedStock = stockLevel === -1;
    const isInStock =
      hasUnlimitedStock || (!hasUnlimitedStock && stockLevel > 0);

    if (cartIndex === -1 && add && isInStock) {
      newCart.push({ ...product, quantity: 1 });
      if (!hasUnlimitedStock) newProducts[productIndex].stockLevel -= 1;
    } else if (add && isInStock) {
      newCart[cartIndex].quantity += 1;
      if (!hasUnlimitedStock) newProducts[productIndex].stockLevel -= 1;
    } else if (subtract && newCart[cartIndex].quantity === 1) {
      newCart.splice(cartIndex, 1);
      if (!hasUnlimitedStock) newProducts[productIndex].stockLevel += 1;
    } else if (subtract) {
      newCart[cartIndex].quantity -= 1;
      if (!hasUnlimitedStock) newProducts[productIndex].stockLevel += 1;
    }

    setCart(newCart);
    setProducts(newProducts);
  };

  let cartQuantity = 0;
  let cartTotal = 0;
  cart.forEach((cartItem) => {
    cartQuantity += cartItem.quantity;
    cartTotal += cartItem.sellingPrice * cartItem.quantity;
  });

  return (
    <>
      {toggle.isVisible && (
        <Modal title={t('cart')} onClose={resetToggle}>
          <TableCart
            hasAlternateBackground
            data={cart}
            updateCart={updateCart}
          />

          <Box height={4} />

          <FormCart
            cartTotal={cartTotal}
            isDebitOrder={
              userPaymentDetails?.paymentDetails?.userDebitStatus?.id === 2
            }
            isFetchingMembers={usersFind.isFetching}
            isFetchingUserPaymentDetails={isFetchingUserPaymentDetails}
            isLoading={storeCreateSale.isLoading}
            members={usersFind?.data?.data || []}
            onChangeCustomerType={(value) => setFormCustomerType(value)}
            onGetUserPaymentDetailsRequest={onGetUserPaymentDetailsRequest}
            onSubmit={onSubmitFormCart}
          />
        </Modal>
      )}

      <Container
        appBarProps={{
          title: t('pointOfSale'),
          breadcrumbs: [t('store')],
          children: cartQuantity > 0 && (
            <Box ml='auto'>
              <Button
                text={`${t('viewCart')} (${cartQuantity})`}
                onClick={setToggle}
              />
            </Box>
          ),
        }}
        isLoading={storeFindStockItems.isFetching}
      >
        <FormQuery
          initialValues={query}
          locationOptions={locationOptions}
          onSubmit={(values) =>
            setQuery((prev) => ({
              ...values,
              sort: prev.sort,
            }))
          }
        />

        <Divider pb={4} />

        {_.isEmpty(storeFindStockItems.data?.data) ? (
          <Text color='grey1' mt={4} textAlign='center' variant='subheading'>
            {storeFindStockItems.isFetching
              ? `${t('fetching')} ${t('products')}`
              : t('noProducts')}
          </Text>
        ) : (
          <Row>
            {products?.map(
              ({ stockLevel, id, name, sellingPrice, imageUrl, sku }) => {
                const cartItem = cart.find((item) => item.id === id);
                const hasUnlimitedStock = stockLevel === -1;
                const isInStock =
                  hasUnlimitedStock || (!hasUnlimitedStock && stockLevel > 0);

                return (
                  <Col key={id} lg={3} md={4} xl={2} xs={6}>
                    <Box
                      hasRadius
                      bg='grey6'
                      opacity={!isInStock ? 0.65 : undefined}
                      overflow='hidden'
                      position='relative'
                    >
                      <Box
                        hasRadius
                        bg='grey5'
                        borderBottomLeftRadius={0}
                        borderTopLeftRadius={0}
                        left={0}
                        position='absolute'
                        px={2}
                        py={1}
                        top={2}
                      >
                        <Text
                          color={
                            !isInStock
                              ? 'danger'
                              : !hasUnlimitedStock && stockLevel < 5
                              ? 'warning'
                              : 'success'
                          }
                          variant='caption'
                        >
                          {!isInStock
                            ? t('outOfStock')
                            : !hasUnlimitedStock
                            ? `${stockLevel} ${t('inStock').toLowerCase()}`
                            : t('inStock')}
                        </Text>
                      </Box>

                      {(isInStock || cartItem) && (
                        <Box
                          hasRadiusBottom
                          isFlex
                          alignItems='center'
                          bg='primary'
                          borderBottomRightRadius={0}
                          height={11}
                          justifyContent='center'
                          position='absolute'
                          right={0}
                          top={0}
                          width={12}
                          onClick={
                            isInStock
                              ? () =>
                                  updateCart({
                                    add: true,
                                    product: {
                                      id,
                                      name,
                                      sellingPrice,
                                      sku,
                                      imageUrl,
                                    },
                                  })
                              : undefined
                          }
                        >
                          <Icon color='fontInverse' name='add_shopping_cart' />

                          {cartItem && (
                            <Text color='fontInverse' variant='caption'>
                              {cartItem.quantity}
                            </Text>
                          )}
                        </Box>
                      )}

                      {isValidUrl(imageUrl) ? (
                        <Image
                          paddingBottom='100%'
                          src={imageUrl}
                          width='100%'
                        />
                      ) : (
                        <Box
                          paddingBottom='100%'
                          pointerEvents='none'
                          position='relative'
                          width='100%'
                        >
                          <Box
                            isFlex
                            alignItems='center'
                            bottom={0}
                            justifyContent='center'
                            left={0}
                            position='absolute'
                            right={0}
                            top={0}
                          >
                            <Text color='grey2'>{t('noImage')}</Text>
                          </Box>
                        </Box>
                      )}

                      <Text m={2} mb={0}>
                        {name}
                      </Text>

                      <Text color='grey1' m={2} mt={0}>
                        {toCurrency({ value: sellingPrice })}
                      </Text>
                    </Box>
                  </Col>
                );
              }
            )}
          </Row>
        )}
      </Container>
    </>
  );
};
