import { Box, ConfirmationDialog, Step } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import {
  useAccessControlFindSystemsById,
  useAccessControlUpdateSystems,
} from 'octiv-hooks/requests/AccessControl';
import { filterLocationsWithAccessControl } from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Form from './Form';

export default ({ acsId }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [pendingValues, setPendingValues] = useState(null);
  const { t } = useTranslation();
  const locationId = Number(searchParams.get('locationId'));

  const {
    tenant: { locations },
  } = useActiveUserTenant();

  const locationOptionsWithAccessControl =
    filterLocationsWithAccessControl(locations);

  const apiGetAcsSystem = useAccessControlFindSystemsById({
    filter: { id: acsId },
  });

  const apiUpdateACSSystem = useAccessControlUpdateSystems({
    meta: { useOnSuccessToast: true },
  });

  const handleDialogClose = () => setOpenDialog(false);

  const handleDialogConfirm = () => {
    if (pendingValues) {
      apiUpdateACSSystem.mutate({ id: acsId, ...pendingValues });
      navigate(
        `/access-control/systems/${acsId}?locationId=${pendingValues.locationId}`
      );
    }
    setOpenDialog(false);
  };

  const handleSubmit = (values) => {
    if (values.locationId !== locationId) {
      // LocationId has changed, show confirmation dialog
      setPendingValues(values);
      setOpenDialog(true);
    } else {
      // Proceed normally if locationId hasn't changed
      apiUpdateACSSystem.mutate({ id: acsId, ...values });
    }
  };

  return (
    <Step bg='grey6' m={3} title={t('editAccessSystem')} value=''>
      <Box p={4}>
        {!apiGetAcsSystem.isFetching && (
          <Form
            initialValues={apiGetAcsSystem.data}
            locationOptions={locationOptionsWithAccessControl}
            onSubmit={handleSubmit}
          />
        )}
      </Box>

      <ConfirmationDialog
        isConfirm
        message={t('changeLocationMessage')}
        open={openDialog}
        securityPrompt={t('changeSystemLocation')}
        title={t('changeSystemLocation')}
        onCancel={handleDialogClose}
        onConfirm={handleDialogConfirm}
      />
    </Step>
  );
};
