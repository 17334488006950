import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import {
  locationAdditionalFeatureOptions,
  paymentGateways,
} from 'octiv-utilities/Constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  healthProviders,
  initialValues = {},
  isCreate,
  isFetchingHealthProviders,
  isFetchingLocationCategories,
  isLoading,
  locationCategories,
  timezones,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        additionalFeatures: initialValues.additionalFeatures || [],
        name: initialValues.name || undefined,
        businessName: initialValues.businessName || undefined,
        prefix: initialValues.prefix || undefined,
        timezoneId: initialValues.timezone?.id || undefined,
        categoryId: initialValues.categoryId || undefined,
        healthProviderIds:
          initialValues.healthProviders && initialValues.healthProviders[0]
            ? initialValues.healthProviders.map((item) => item.id)
            : undefined,
        paymentGatewayId: initialValues.paymentGatewayId || undefined,
        sageMerchantAccountNumber:
          initialValues.debitOrderLocationPaymentGateway
            ?.netcashMerchantAccountNumber || undefined,
        sageAccountServiceKey:
          initialValues.debitOrderLocationPaymentGateway
            ?.netcashAccountServiceKey || undefined,
        sageDebitOrderServiceKey:
          initialValues.debitOrderLocationPaymentGateway
            ?.netcashDebitOrderServiceKey || undefined,
        threePeaksDevId:
          initialValues.debitOrderLocationPaymentGateway?.threePeaksDevId ||
          undefined,
        threePeaksDevToken:
          initialValues.debitOrderLocationPaymentGateway?.threePeaksDevToken ||
          undefined,
        threePeaksCref:
          initialValues.debitOrderLocationPaymentGateway?.threePeaksCref ||
          undefined,
        canDebit: initialValues.canDebit === 1 ? 1 : 0,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(t('required')),
        businessName: Yup.string().required(t('required')),
        prefix: Yup.string().required(t('required')),
        timezoneId: Yup.string(),
        categoryId: Yup.string().required(t('required')),
        healthProviderIds: Yup.array(),
        paymentGatewayId: Yup.string().required(t('required')),
        sageMerchantAccountNumber: Yup.string(),
        sageAccountServiceKey: Yup.string(),
        sageDebitOrderServiceKey: Yup.string(),
        threePeaksDevId: Yup.string(),
        threePeaksDevToken: Yup.string(),
        threePeaksCref: Yup.string(),
        canDebit: Yup.bool(),
      })}
    >
      {({ handleSubmit, values: { paymentGatewayId } }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field label='Location Name' name='name' />
            </Col>

            <Col>
              <Field label='Location Business Name' name='businessName' />
            </Col>

            <Col>
              <Field label='Location Invoice Prefix' name='prefix' />
            </Col>

            <Col>
              <Field
                isSelect
                label='Timezone'
                name='timezoneId'
                options={[
                  {
                    label: 'Use Facility Timezone',
                    value: undefined,
                  },
                  // eslint-disable-next-line no-unsafe-optional-chaining
                  ...(timezones || []).map?.((item) => ({
                    label: item.name,
                    value: item.id,
                  })),
                ]}
              />
            </Col>

            <Col>
              <Field
                isSelect
                isLoading={isFetchingLocationCategories}
                label='Location Category'
                name='categoryId'
                options={locationCategories.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
            </Col>

            <Col>
              <Field
                isMulti
                isSelect
                isLoading={isFetchingHealthProviders}
                label='Health Providers'
                name='healthProviderIds'
                options={healthProviders.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
            </Col>

            <Col>
              <Field
                isSelect
                label={t('paymentGateway')}
                name='paymentGatewayId'
                options={[
                  {
                    label: 'No Gateway',
                    value: paymentGateways.NO_PAYMENT,
                  },
                  {
                    label: 'Sage',
                    value: paymentGateways.SAGE,
                  },
                  {
                    label: 'Three Peaks',
                    value: paymentGateways.THREE_PEAKS,
                  },
                  {
                    label: 'Netcash',
                    value: paymentGateways.NETCASH,
                  },
                  {
                    label: 'GoCardless',
                    value: paymentGateways.GO_CARDLESS,
                  },
                  {
                    label: 'Stripe',
                    value: paymentGateways.STRIPE,
                  },
                  {
                    label: 'SEPA',
                    value: paymentGateways.SEPA,
                  },
                ]}
              />
            </Col>

            <Col>
              <Field
                isMulti
                isSelect
                label={t('additionalFeatures')}
                name='additionalFeatures'
                options={locationAdditionalFeatureOptions}
              />
            </Col>

            {paymentGatewayId === paymentGateways.NETCASH && (
              <>
                <Col>
                  <Field
                    label={t('merchantAccountNumber')}
                    name='sageMerchantAccountNumber'
                  />
                </Col>

                <Col>
                  <Field
                    label={t('accountServiceKey')}
                    name='sageAccountServiceKey'
                  />
                </Col>

                <Col>
                  <Field
                    label={t('debitOrderServiceKey')}
                    name='sageDebitOrderServiceKey'
                  />
                </Col>
              </>
            )}

            {paymentGatewayId === paymentGateways.THREE_PEAKS && (
              <>
                <Col>
                  <Field label='Dev Id' name='threePeaksDevId' />
                </Col>

                <Col>
                  <Field label='Dev Token' name='threePeaksDevToken' />
                </Col>

                <Col>
                  <Field label='Cref' name='threePeaksCref' />
                </Col>
              </>
            )}

            <Col>
              <Field
                isCheckbox
                name='canDebit'
                options={[{ label: 'Can process debits?', value: 1 }]}
              />
            </Col>

            <Col>
              <Button isLoading={isLoading} text={t('submit')} type='submit' />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
