import moment from 'moment';
import { Box, Container, Modal, Text } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { usePaging, useToggle } from 'octiv-hooks';
import {
  useStoreFindByIdReportsSaleDetails,
  useStoreFindReportsSales,
} from 'octiv-hooks/requests/Store';
import { getDateYearMonthDay } from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormQuery from './FormQuery';
import TableDetail from './TableDetail';
import TableReport from './TableReport';

export default () => {
  const { t } = useTranslation();

  const {
    selectedLocation,
    tenant: { locationOptions },
    toCurrency,
  } = useActiveUserTenant();
  const [toggle, setToggle, resetToggle] = useToggle();
  const [salesTotal, setSalesTotal] = useState(undefined);
  const [paging, setPaging] = usePaging();
  const [query, setQuery] = useState({
    locationId: selectedLocation?.id,
    status: undefined,
    startDate: getDateYearMonthDay({ date: moment().subtract(1, 'month') }),
    endDate: getDateYearMonthDay({ canFallback: true }),
  });

  const { data: report, isFetching: isFetchingReport } =
    useStoreFindReportsSales(
      { filter: query, paging },
      {
        onSuccess: (response) => {
          if (response && response?.data && response?.data?.[0]) {
            setSalesTotal(
              response.data.reduce((a, b) => a + Number(b.cartAmount), 0)
            );
          } else {
            setSalesTotal(undefined);
          }
        },
      }
    );

  const { data: reportDetail, isFetching: isFetchingReportDetail } =
    useStoreFindByIdReportsSaleDetails(
      {
        id: toggle.data?.id,
        filter: query,
      },
      {
        enabled: toggle.data !== undefined,
      }
    );

  return (
    <>
      {toggle.isVisible && (
        <Modal title='' onClose={resetToggle}>
          <TableDetail data={reportDetail} isLoading={isFetchingReportDetail} />
        </Modal>
      )}

      <Container
        appBarProps={{
          title: t('storeSales'),
          breadcrumbs: [t('reports'), t('finance')],
        }}
        isLoading={isFetchingReport || isFetchingReportDetail}
      >
        <FormQuery
          initialValues={query}
          locationOptions={locationOptions}
          onSubmit={setQuery}
        />

        <Box height={4} />

        <TableReport
          data={report?.data || []}
          isLoading={isFetchingReport}
          paging={report?.paging}
          setPaging={setPaging}
          onClickRow={({ original }) => setToggle({ data: original })}
        />

        {salesTotal && !isFetchingReport && (
          <Text textAlign='right' variant='heading'>
            {`${t('total')}: ${toCurrency({
              value: salesTotal,
            })}`}
          </Text>
        )}
      </Container>
    </>
  );
};
