import { Box, Button, Step } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useSignedInUser } from 'octiv-context/SignedInUser';
import { usePaging } from 'octiv-hooks';
import {
  useAccessControlFindSystemPackages,
  useUserListFind,
} from 'octiv-hooks/requests/AccessControl';
import { getSelectedRows } from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import FormQuery from '../common/FormQuery';
import TableImportOctivUsers from './TableImportOctivUsers';

export default ({ onCancel, onSubmit, acsId }) => {
  const [paging, setPaging] = usePaging();
  const [searchParams] = useSearchParams();
  const [packages, setPackages] = useState([]);
  const [importedUsers, setImportedUsers] = useState([]);
  const locationId = Number(searchParams.get('locationId'));

  const {
    tenant: { locationOptionsAll },
  } = useActiveUserTenant();

  const packagesFind = useAccessControlFindSystemPackages(
    {
      filter: { locationId },
      paging: { perPage: -1 },
    },
    {
      onSuccess: (response) => setPackages(response?.data),
    }
  );

  const { userTenants } = useSignedInUser();
  const limitInterFacilityBookings =
    userTenants?.[0]?.tenant?.limitInterFacilityBookings;
  const { t } = useTranslation();

  const [query, setQuery] = useState({
    userType: 'member',
    search: undefined,
    sort: 'name',
    userTenantPackageId: undefined,
    ...(!limitInterFacilityBookings && { locationId }),
  });
  // Fetch selected rows
  const selectedData = getSelectedRows({
    data: importedUsers?.length > 0 ? importedUsers : [],
  });

  const usersImportFind = useUserListFind(
    {
      filter: {
        systemId: acsId,
        statusId: 2,
        userTenantPackageId: query.userTenantPackageId,
        userTenantStatusId: '1,2',
        userTenantLocationId: query.locationId,
        search: query.search,
        ...(query.userType === 'staff'
          ? { userTenantTypeId: '2,3,5,6,8', userTenantStatusId: '2' }
          : query.userType === 'member'
          ? { userTenantTypeId: '4' }
          : { userTenantTypeId: '9' }), // Leads
      },
      paging,
    },
    {
      onSuccess: (response) => setImportedUsers(response?.data || []),
    }
  );

  return (
    <>
      <Step
        boxContainerProps={{ mb: 4 }}
        mb={2}
        title={t('selectUsersAccessControl')}
        value='1'
      />

      <FormQuery
        initialValues={query}
        isFetchingPackages={packagesFind.isFetching}
        limitInterFacilityBookings={limitInterFacilityBookings}
        locationOptionsAll={locationOptionsAll}
        packages={packages}
        onSubmit={setQuery}
      />

      <Box mb={4} />

      <TableImportOctivUsers
        hasAlternateBackground
        data={importedUsers}
        isLoading={usersImportFind?.isFetching}
        paging={usersImportFind?.data?.paging}
        setPaging={setPaging}
        setSelected={setImportedUsers}
      />
      <Box isFlex flexDirection='row' mt={2}>
        <Button
          mr={3}
          text={t('next')}
          onClick={() => onSubmit(selectedData.map((user) => user.id))}
        />
        <Button hasBorder text={t('cancel')} onClick={onCancel} />
      </Box>
    </>
  );
};
