import { Box, Chip, ConfirmationDialog, Modal } from 'octiv-components';
import { useToggle } from 'octiv-hooks';
import {
  useAccessControlCredentialsAccessMethod,
  useAccessControlDeleteSystemCredentials,
  useAccessControlFindSystemCredentials,
} from 'octiv-hooks/requests/AccessControl';
import { useAcsEvents } from 'octiv-hooks/useAcsEvents';
import { AcsEvent } from 'octiv-utilities/Constants';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import FormAccessCredential from './FormAccessCredential';
import TableAccessCredentials from './TableAccessCredentials';

export default ({ manageUserId, acsId }) => {
  const { t } = useTranslation();
  const [toggle, setToggle, resetToggle] = useToggle();
  const [searchParams] = useSearchParams();
  const [openDialog, setOpenDialog] = useState(false);
  const [credentialToDelete, setCredentialToDelete] = useState(null);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);

  const locationId = Number(searchParams.get('locationId'));

  const apiFind = useAccessControlFindSystemCredentials({
    filter: {
      id: acsId,
      acsUserId: manageUserId,
    },
  });

  const apiSetAccessMethod = useAccessControlCredentialsAccessMethod({
    onSuccess: () => {
      resetToggle();
    },
  });

  const apiDelete = useAccessControlDeleteSystemCredentials({
    meta: { useOnSuccessToast: true },
  });

  const onAccessMethodSelect = ({ accessCredentialsMethod }) => {
    apiSetAccessMethod.mutate({
      id: acsId,
      acsUserIds: [manageUserId],
      accessMethod: accessCredentialsMethod,
    });
  };

  useAcsEvents(
    locationId,
    [AcsEvent.CREDENTIALS_CREATED, AcsEvent.CREDENTIALS_DELETED],
    () => {
      apiFind.refetch();
    }
  );

  const onClickAction = ({ action, id }) => {
    switch (action) {
      case 'delete':
        setCredentialToDelete(id);
        setOpenDialog(true);
        break;
      default:
        break;
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDeleteCredential = () => {
    if (credentialToDelete) {
      apiDelete.mutate({
        id: acsId,
        acsCredentialId: credentialToDelete,
      });
    }
    setOpenDialog(false);
  };

  const handleAddCredentialClick = () => {
    const credentials = apiFind?.data;
    if (credentials.length >= 2) {
      setInfoDialogOpen(true);
    } else {
      setToggle({ isVisible: true });
    }
  };

  const handleInfoDialogClose = () => {
    setInfoDialogOpen(false);
  };

  return (
    <Box>
      {toggle.isVisible && (
        <Modal isSidebar title={t('createCredential')} onClose={resetToggle}>
          <FormAccessCredential onSubmit={onAccessMethodSelect} />
        </Modal>
      )}

      <Box display='flex' justifyContent='flex-end' mb={2}>
        <Chip
          hasAlternateBackground
          icon='add'
          my={2}
          title={t('newCredential')}
          onClick={handleAddCredentialClick}
        />
      </Box>

      <TableAccessCredentials
        hasAlternateBackground
        data={apiFind?.data}
        isLoading={apiFind?.isFetching}
        onClickAction={onClickAction}
      />

      <ConfirmationDialog
        isDanger
        cancelText={t('cancel')}
        confirmText={t('delete')}
        open={openDialog}
        title={t('deleteSystemCredentialConfirmation')}
        onCancel={handleDialogClose}
        onConfirm={handleDeleteCredential}
      />

      <ConfirmationDialog
        isInfo
        message={t('accessMethodCredentialFull')}
        open={infoDialogOpen}
        title={t('credentialLimitReached')}
        onCancel={handleInfoDialogClose}
      />
    </Box>
  );
};
