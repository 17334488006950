import Echo from 'laravel-echo';
import { apiBaseUrl } from 'octiv-utilities/Constants';
import Pusher from 'pusher-js';

window.Pusher = Pusher;

const jwt = localStorage.getItem('jwt');

const echo = new Echo({
  authEndpoint: `${apiBaseUrl}/broadcasting/auth`,
  broadcaster: 'pusher',
  key: process.env.REACT_APP_ABLY_KEY ?? '',
  wsHost: 'realtime-pusher.ably.io',
  wsPort: 443,
  disableStats: true,
  encrypted: true,
  cluster: 'mt1',
  bearerToken: `${jwt}`,
});

export default echo;
