import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ onBack, onSubmit, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        accessCredentialsMethod: '',
      }}
      validationSchema={Yup.object().shape({
        accessCredentialsMethod: Yup.string().required(
          t('required', { field: t('accessMethod') })
        ),
      })}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row width={500}>
            <Col>
              <Field
                hasAlternateBackground
                isSelect
                label={t('accessMethod')}
                name='accessCredentialsMethod'
                options={[
                  { label: t('code'), value: 'code' },
                  { label: t('mobile'), value: 'mobile' },
                ]}
              />
            </Col>

            <Col isFlex flexDirection='row' mt={2}>
              <Button mr={3} text={t('confirm')} type='submit' />
              <Button hasBorder text={t('back')} onClick={() => onBack()} />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
