import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  isFetchingPackagesForATenant,
  isLoading,
  tenantPackages,
  existingPackageIdsInGroup,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={{
        packageIds: isFetchingPackagesForATenant
          ? []
          : tenantPackages
              .filter((item) => existingPackageIdsInGroup.includes(item.id))
              .map((item) => item.id),
      }}
      validationSchema={Yup.object().shape({
        packageIds: Yup.array(),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field
                hasSelectAll
                isArray
                isCheckbox
                isLoading={isFetchingPackagesForATenant}
                label={t('packages')}
                name='packageIds'
                options={tenantPackages?.map(({ id, name }) => ({
                  label: name,
                  value: id,
                }))}
              />
            </Col>

            <Col>
              <Button isLoading={isLoading} text={t('submit')} type='submit' />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
