import { Modal } from 'octiv-components';
import { useAccessControlCreateUsers } from 'octiv-hooks/requests/AccessControl';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormACSGroups from './FormACSGroups';
import FormOctivUsers from './FormOctivUsers';
import FormSelectAccessMethod from './FormSelectAccessMethod';

export default ({ acsId, onHideModal }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [userIds, setUserIds] = useState([]);
  const [acsGroupIds, setAcsGroupIds] = useState([]);
  const { t } = useTranslation();

  const apiConnectUsersAndGroups = useAccessControlCreateUsers({
    meta: { useOnSuccessToast: true },
  });

  const handleAddUsers = (usersIds) => {
    setUserIds(usersIds);
    setActiveStep(1);
  };

  const handleAddUserGroups = (acsGroupsIds) => {
    setAcsGroupIds(acsGroupsIds);
    setActiveStep(2);
  };

  const handleSelectAccessMethod = (selectedAccessMethod) => {
    apiConnectUsersAndGroups.mutate({
      id: acsId,
      acsGroupIds,
      userIds,
      accessMethod: selectedAccessMethod,
    });

    onHideModal();
  };

  return (
    <Modal title={t('importUsers')} onClose={onHideModal}>
      {activeStep === 0 && (
        <FormOctivUsers
          acsId={acsId}
          onCancel={onHideModal}
          onSubmit={handleAddUsers}
        />
      )}
      {activeStep === 1 && (
        <FormACSGroups
          acsId={acsId}
          onBack={() => setActiveStep(0)}
          onSubmit={handleAddUserGroups}
        />
      )}
      {activeStep === 2 && (
        <FormSelectAccessMethod
          onBack={() => setActiveStep(1)}
          onSubmit={handleSelectAccessMethod}
        />
      )}
    </Modal>
  );
};
