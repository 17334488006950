import { Box, Button, Chip, Icon } from 'octiv-components';
import { AccessContext } from 'octiv-context';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// recipient = { id, name, surname }

const SendComm = ({
  buttonProps,
  chipProps,
  iconProps,
  isChip,
  isIcon,
  leads,
  members,
  nonMembers,
  staff,
  text,
  tooltipText,
  ...props
}) => {
  const { t } = useTranslation();
  const hasAccess = useContext(AccessContext);

  const getRecipients = (recipients) =>
    recipients
      ? recipients
          ?.filter(
            (recipient) =>
              (!!recipient?.id && !!recipient?.name) ||
              (!!recipient?.user?.id && !!recipient?.user?.name)
          )
          ?.map((recipient) => {
            if (recipient?.user) {
              return {
                id: recipient.user.id,
                name: recipient.user.name,
                surname: recipient.user.surname,
              };
            }

            return {
              id: recipient?.id,
              name: recipient?.name,
              surname: recipient?.surname,
            };
          })
      : [];

  const recipients = useMemo(
    () =>
      hasAccess.containerComms
        ? {
            members: getRecipients(members),
            staff: getRecipients(staff),
            leads: getRecipients(leads),
            nonMembers: getRecipients(nonMembers),
          }
        : undefined,
    [hasAccess.containerComms, members, staff, leads, nonMembers]
  );

  const hasRecipients =
    recipients?.members?.length > 0 ||
    recipients?.staff?.length > 0 ||
    recipients?.leads?.length > 0 ||
    recipients?.nonMembers?.length > 0;

  if (!hasAccess.containerComms) return null;

  return (
    <Box
      style={
        hasRecipients
          ? undefined
          : {
              cursor: 'not-allowed',
              opacity: 0.7,
            }
      }
      {...props}
    >
      <Link
        state={hasRecipients ? { recipients } : undefined}
        style={
          hasRecipients
            ? undefined
            : {
                pointerEvents: 'none',
              }
        }
        to='/comms/create'
      >
        {isIcon ? (
          <Icon
            {...iconProps}
            color={hasRecipients ? 'primary' : undefined}
            name='forum'
            tooltip={text === undefined ? t('sendComm') : text}
          />
        ) : isChip ? (
          <Chip
            hasAlternateBackground
            {...chipProps}
            icon='forum'
            title={text === undefined ? t('sendComm') : text}
          />
        ) : (
          <Button
            {...buttonProps}
            icon='forum'
            iconProps={{ scale: 'small' }}
            text={text === undefined ? t('sendComm') : text}
            tooltip={tooltipText === undefined ? '' : tooltipText}
          />
        )}
      </Link>
    </Box>
  );
};

export default SendComm;
