import { Step } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import AccessMethodSelect from './AccessMethodSelect';

export default ({ onBack, onSubmit }) => {
  const { t } = useTranslation();

  const handleSubmit = ({ accessCredentialsMethod }) => {
    onSubmit(accessCredentialsMethod);
  };

  return (
    <>
      <Step
        boxContainerProps={{ mb: 4 }}
        mb={3}
        title={t('selectAccessMethod')}
        value='3'
      />
      <AccessMethodSelect onBack={onBack} onSubmit={handleSubmit} />
    </>
  );
};
