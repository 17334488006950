import { Formik } from 'formik';
import { Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ locationOptionsAll, isFetching, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      validationSchema={Yup.object().shape({
        search: Yup.string(),
        userLinkedStatus: Yup.string(),
      })}
    >
      {({ handleSubmit, values: { userType } }) => (
        <form onSubmit={handleSubmit}>
          <Row alignItems='center' display='flex'>
            {userType !== 'nonMember' && (
              <Col md={7}>
                <Field
                  autoFocus
                  hasSubmitButtonSearch
                  label={t('search')}
                  name='search'
                />
              </Col>
            )}

            <Col md={5}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('linkedStatus')}
                name='userLinkedStatus'
                options={[
                  { label: t('linked'), value: 'linked' },
                  { label: t('notLinked'), value: 'notLinked' },
                ]}
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
