import { Box, Button, Table, Text } from 'octiv-components';
import { getFullName, getUserTypeString } from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, loggedInUserTypeId, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'name',
        Cell: ({ row: { original } }) => getFullName(original),
      },
      {
        Header: t('type'),
        accessor: 'userTenant.type.id',
        Cell: ({ value }) => <Text>{getUserTypeString({ id: value })}</Text>,
      },
      {
        Header: (
          <Box isFlex flexDirection='row' justifyContent='center' width='100%'>
            {t('action')}
          </Box>
        ),
        accessor: 'id',
        Cell: ({ value }) => (
          <Box isFlex flexDirection='row' justifyContent='center'>
            <Button
              hasBorder
              scale='small'
              text={t('linkUser')}
              onClick={() =>
                onClickAction({
                  action: 'confirmLink',
                  id: value,
                })
              }
            />
          </Box>
        ),
      },
      //   {
      //     Header: t('actions'),
      //     accessor: 'id',
      //     Cell: ({ row: { original } }) => {
      //       return (
      //         <Tooltip
      //           stopPropagation
      //           options={[
      //             // TODO: conditionally render suspend or unsuspend with a tenary
      //             { label: t('delete'), value: 'delete' },
      //           ]}
      //           onClick={({ value }) =>
      //             onClickAction({
      //               action: value,
      //               id: original.id,
      //             })
      //           }
      //         />
      //       );
      //     },
      //   },
    ],
    [onClickAction, t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
