import { Formik } from 'formik';
import { Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, initialValues, onValuesChange, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        isDefaultForStaff:
          initialValues.settings?.isDefaultForStaff || undefined,
        isDefaultForMembers:
          initialValues.settings?.isDefaultForMembers || undefined,
      }}
      validationSchema={Yup.object().shape({
        isDefaultForStaff: Yup.boolean(),
        isDefaultForMembers: Yup.boolean(),
      })}
      onChange={(values) => {
        onValuesChange?.(values);
      }}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <form onSubmit={handleSubmit}>
          <Row my={5}>
            <Col>
              <Field
                isCheckbox
                name='isDefaultForStaff'
                options={[
                  {
                    label: t('assignAllStaff'),
                    value: true,
                  },
                ]}
                onChange={(value) => {
                  setFieldValue('isDefaultForStaff', value);
                }}
              />
            </Col>
            <Col>
              <Field
                isCheckbox
                name='isDefaultForMembers'
                options={[
                  {
                    label: t('assignAllMembers'),
                    value: true,
                  },
                ]}
                onChange={(value) => {
                  setFieldValue('isDefaultForMembers', value);
                }}
              />
            </Col>
          </Row>
          {onValuesChange?.(values)}
        </form>
      )}
    </Formik>
  );
};
