import { Table, Text } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import {
  getDateReadableDayMonthYear,
  getFullName,
  toCapital,
} from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const { toCurrency } = useActiveUserTenant();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('code'),
        accessor: 'code',
      },
      {
        Header: t('member'),
        accessor: 'purchaser',
        Cell: ({
          row: {
            original: { leadMember, nonMember, user },
          },
        }) => {
          const purchaser = leadMember || nonMember || user;
          return getFullName(purchaser);
        },
      },
      {
        Header: t('description'),
        accessor: 'description',
        whiteSpaceWrap: true,
      },
      {
        Header: t('status'),
        accessor: 'status',
        Cell: ({ value }) => (
          <Text
            as='span'
            color={
              value === 'pending' || value === 'submitted'
                ? 'warning'
                : value === 'credited'
                ? 'info'
                : value === 'paid'
                ? 'success'
                : value === 'unpaid'
                ? 'danger'
                : undefined
            }
          >
            {toCapital({ value })}
          </Text>
        ),
      },
      {
        Header: t('dueDate'),
        accessor: 'dueOn',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
      {
        Header: t('sentDate'),
        accessor: 'sentAt',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
      {
        Header: t('paymentRequestSentDate'),
        accessor: 'paymentRequestSentAt',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
      {
        Header: t('outstanding'),
        id: 'outstanding',
        Cell: ({
          row: {
            original: { amount, payments = [] },
          },
        }) =>
          toCurrency({
            value: payments.reduce((a, b) => a - b.amount, amount),
          }),
      },
      {
        Header: t('amount'),
        accessor: 'amount',
        Cell: ({ value }) => toCurrency({ value }),
      },
    ],
    [toCurrency, t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
