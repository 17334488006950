import { Box } from 'octiv-components';
import { useAccessControlFindEntrances } from 'octiv-hooks/requests/AccessControl';
import React from 'react';

import Table from './Table';

export default ({ acsId }) => {
  const apifindEntrances = useAccessControlFindEntrances({
    filter: {
      id: acsId,
    },
    // enabled: false,
  });

  return (
    <Box hasRadiusBottom bg='grey6' p={4}>
      <Table
        hasAlternateBackground
        data={
          apifindEntrances.isFetching
            ? []
            : apifindEntrances?.data || [
                {
                  id: '4268e48e-dcc5-491d-bb13-78fe9f931801',
                  name: 'UNKNOWN THING',
                  providerDeviceId: '1d88b78c-0169-4827-967f-7d40c66666de',
                  createdAt: '2024-11-15T07:21:32.995Z',
                },
              ]
        }
        isLoading={apifindEntrances.isFetching}
      />
    </Box>
  );
};
