import { saveAs } from 'file-saver';
import firebase from 'firebase/app';
import jwtDecode from 'jwt-decode';
import _ from 'lodash';
import moment from 'moment';
import i18n from 'octiv-config/i18n';

import { measures } from './Constants';

export const logEvent = ({
  name,
  container,
  component,
  useFirebase = true,
  firebaseParams = {},
  isScreen,
  ...params
}) => {
  if (name) {
    const analytics = firebase.analytics();

    const parameters = {
      ...(container && { container }),
      ...(component && { component }),
      ...params,
    };

    if (useFirebase && isScreen) {
      const screenName = name
        .split(/(?=[A-Z])/)
        .join('_')
        .toLowerCase();

      analytics.setCurrentScreen(screenName);
      analytics.logEvent('screen_view');
    } else if (useFirebase) {
      analytics.logEvent(name, {
        ...parameters,
        ...firebaseParams,
      });
    }
  }
};

export const isLocalStorageAvailable = () => {
  try {
    const test = 'test_local_storage_available';
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (error) {
    return false;
  }
};

export const isValidUrl = (value) => {
  if (
    !value ||
    value.includes('sign-up-profile-image') ||
    value.charAt(value.length - 1) === '/'
  ) {
    return false;
  }

  if (
    value.includes('/static/media/') ||
    value.includes('data:') ||
    value.includes('file://') ||
    value.includes('blob:')
  ) {
    return true;
  }

  let url;

  try {
    url = new URL(value);
  } catch (error) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const urlSearchParam = ({ param, isDelete = false }) => {
  const url = new URL(window.location);

  const { searchParams } = url;

  if (param && isDelete) {
    url.searchParams.delete(param);
    // eslint-disable-next-line no-restricted-globals
    history.replaceState(null, null, url);
  } else if (param) {
    return searchParams.get(param);
  }

  return searchParams;
};

// NOTE: This is just a fallback... not very safe IMHO
const getFilenameFromUrl = (url) => {
  const urlParts = decodeURI(url)?.split('/');
  return urlParts[(urlParts?.length || 1) - 1]?.split('?')?.[0];
};

export const downloadFile = (url) => {
  if (url) {
    let filename;

    fetch(url)
      .then((res) => {
        filename = res.headers
          .get('Content-Disposition')
          ?.split('filename=')[1]
          ?.split(';')[0];

        // NB: Exposing the header above on our current bucket exposes security risks.
        // This is  workaround to get the filename
        filename = getFilenameFromUrl(url);

        return res.blob();
      })
      .then((blob) => saveAs(blob, filename))
      .catch(() => saveAs(url));
  }
};

export const determineScale = ({ scale, scales }) => {
  switch (scale) {
    case 'small':
      return scales[0];

    case 'medium':
      return scales[2];

    case 'large':
      return scales[3];

    case 'regular':
    default:
      return scales[1];
  }
};

export const getAge = ({ dateOfBirth }) => moment().diff(dateOfBirth, 'years');

export const getFullName = ({
  name: paramName,
  surname: paramSurname,
  user = {},
  isShortened = false,
} = {}) => {
  const name = paramName || user?.name || '';
  const surname = paramSurname || user?.surname || '';

  return `${name || ''}${surname && name ? ' ' : ''}${
    surname && isShortened ? `${surname?.charAt(0)}` : surname || ''
  }`;
};

export const getExercisePart = ({ index }) => String.fromCharCode(index + 65);

export const toggleRowSelected = ({ data, id, key = 'id' }) => {
  const foundIndex = data.findIndex((item) => item[key] === id);

  if (foundIndex > -1) {
    const newData = Array.from(data);
    newData[foundIndex].isSelected = !newData[foundIndex].isSelected;
    return newData;
  }

  return data;
};

export const toggleAllRowsSelected = ({ data, isAllSelected = false }) => {
  const newData = data.map((item) => ({
    ...item,
    isSelected: !isAllSelected,
  }));

  return newData;
};

export const getSelectedRows = ({ data }) => {
  if (_.isEmpty(data)) return [];
  return data?.filter((item) => item.isSelected) || [];
};

export const getDateJs = ({ date, canFallback = false }) =>
  date || canFallback ? moment(date).toDate() : null;

export const getDateIso = ({ date, canFallback = false }) =>
  date || canFallback ? moment(date).format() : null;

export const getDateTime = ({ date, canFallback = false }) =>
  date || canFallback ? moment(date).format('HH:mm:ss') : null;

export const getDateYearMonthDay = ({ date, canFallback = false }) =>
  date || canFallback
    ? moment(date).locale(i18n.resolvedLanguage).format('YYYY-MM-DD')
    : null;

export const getDateFromTime = ({ date = moment(), time = '00:00:00' }) =>
  moment(`${getDateYearMonthDay({ date })}T${time}`).toDate();

export const getDateReadableDayMonthYear = ({ date, canFallback = false }) =>
  date || canFallback
    ? moment(date).locale(i18n.resolvedLanguage).format('DD MMM YYYY')
    : null;

export const getDateReadableDayMonthYearShort = ({
  date,
  canFallback = false,
}) =>
  date || canFallback
    ? moment(date).locale(i18n.resolvedLanguage).format("DD MMM 'YY")
    : null;

export const getDateReadableDay = ({ date, canFallback = false }) =>
  date || canFallback
    ? moment(date).locale(i18n.resolvedLanguage).format('dd')
    : null;

export const getDateReadableMonth = ({ date, canFallback = false }) =>
  date || canFallback
    ? moment(date).locale(i18n.resolvedLanguage).format('MMM')
    : null;

export const getDateReadableDayMonth = ({ date, canFallback = false }) =>
  date || canFallback
    ? moment(date).locale(i18n.resolvedLanguage).format('DD MMM')
    : null;

export const getDateReadableDayMonthYearTime = ({
  date,
  canFallback = false,
}) =>
  date || canFallback
    ? moment(date).locale(i18n.resolvedLanguage).format('DD MMM YYYY - HH:mm')
    : null;

export const getDateDay = ({ date, canFallback = false }) =>
  date || canFallback ? moment(date).format('D') : null;

export const getDateYear = ({ date, canFallback = false }) =>
  date || canFallback ? moment(date).format('YYYY') : null;

export const getDateCalendar = ({ date, canFallback = false }) =>
  date || canFallback
    ? moment(date).locale(i18n.resolvedLanguage).calendar(null, {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd, DD/MM/YYYY',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd, DD/MM/YYYY',
        sameElse: 'DD/MM/YYYY',
      })
    : null;

export const getDateDayMonthYearExpanded = ({ date, canFallback = false }) =>
  date || canFallback
    ? moment(date).locale(i18n.resolvedLanguage).format('dddd, D MMMM YYYY')
    : null;

export const getDateDayMonthExpanded = ({ date, canFallback = false }) =>
  date || canFallback
    ? moment(date).locale(i18n.resolvedLanguage).format('dddd, D MMMM')
    : null;

export const getDateTimeHoursMinutes = ({ date, canFallback = false }) =>
  date || canFallback ? moment(date).format('HH:mm') : null;

export const getDateRange = ({ start, end }) => {
  const dateRange = [];
  let currentDate = moment(start);
  const endDate = moment(end);

  while (currentDate <= endDate) {
    dateRange.push(moment(currentDate));
    currentDate = moment(currentDate).add(1, 'days');
  }

  return dateRange;
};

export const toCurrency = ({ value, code, isShorthand = false }) => {
  if (!code) return '';

  try {
    const parsedValue = parseFloat(
      value.toString().includes('.') ? value : `${value}.00`
    );

    if (isShorthand) {
      const isMillions = parsedValue >= 1000000;

      const withDecimal = parseFloat(
        (parsedValue / (isMillions ? 1000000 : 1000)).toFixed(1)
      );

      if (Number.isInteger(withDecimal)) {
        // Remove decimal as it is a whole number
        return `${withDecimal.toFixed(0)}${isMillions ? 'm' : 'k'}`;
      }

      return `${withDecimal}${isMillions ? 'm' : 'k'}`;
    }

    return parsedValue.toLocaleString(undefined, {
      style: 'currency',
      currency: code,
    });
  } catch (error) {
    return '';
  }
};

export const toCapital = ({ value, shouldCapitalizeAll = true }) => {
  if (typeof value !== 'string') return '';

  if (shouldCapitalizeAll) {
    return value
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
      .join(' ');
  }

  return value.toLowerCase().charAt(0).toUpperCase() + value.substring(1);
};

export const toCase = ({
  value,
  isCamelCase = false,
  convertToTitleCase = true,
}) => {
  if (typeof value !== 'string') return '';

  return toCapital({
    value: isCamelCase
      ? value.replace(/([A-Z])/g, ' $1')
      : value.replace(/_|-/gi, ' '),
    shouldCapitalizeAll: convertToTitleCase,
  });
};

export const getProratedAmount = ({ date, amount }) => {
  if (!amount) return '';

  const daysInMonth = moment(date).daysInMonth();
  const daysRemainingInMonth = daysInMonth - (moment(date).date() - 1);

  const ratePerDay = amount / daysInMonth;
  const proratedAmount = (ratePerDay * daysRemainingInMonth).toFixed(2);

  if (Number.isInteger(parseFloat(proratedAmount))) {
    return parseFloat(proratedAmount);
  }

  return proratedAmount;
};

export const getResult = ({ measureId, score, isRx = undefined }) => {
  if (!measureId) return '';

  const splitScore = score ? score.toString().split('.') : [];

  const measure = measures.find((item) => item.measureId === Number(measureId));

  return `${measure.fields
    .map((field, fieldIndex) =>
      field.decimal
        ? `${score || '0'}${field.short}`
        : splitScore && splitScore[fieldIndex]
        ? `${splitScore[fieldIndex]}${field.short}`
        : splitScore && fieldIndex === 0
        ? `0${field.short}`
        : ''
    )
    .join(' ')}${isRx === undefined ? '' : isRx ? ' (RX)' : ' (SC)'}`;
};

export const getResultAsArray = ({ measureId, score }) => {
  if (!measureId) return [];

  const splitScore = score ? score?.split('.') : [];

  const measure = measures.find((item) => item.measureId === Number(measureId));

  return measure.fields.map((field, fieldIndex) =>
    Number(
      field.decimal
        ? score || 0
        : splitScore && splitScore[fieldIndex]
        ? splitScore[fieldIndex]
        : splitScore && fieldIndex === 0
        ? 0
        : 0
    )
  );
};

export const getMandateStatusDetails = ({
  status,
  sentAt,
  isGoCardless = false,
} = {}) => {
  const isActive = status === 'active';
  const isSent =
    !!sentAt &&
    (!status ||
      status === 'pending' ||
      (!isGoCardless && status === 'created'));
  const isInactive = !isSent && !status;

  return {
    isActive,
    isInactive,
    status: isSent
      ? i18n.t('sent')
      : isInactive
      ? i18n.t('inactive')
      : toCase({ value: status }),
  };
};

export const getRecurringDay = ({ day, isShort = false }) => {
  switch (day) {
    case 1:
      return isShort ? i18n.t('mon') : i18n.t('monday');

    case 2:
      return isShort ? i18n.t('tue') : i18n.t('tuesday');

    case 3:
      return isShort ? i18n.t('wed') : i18n.t('wednesday');

    case 4:
      return isShort ? i18n.t('thu') : i18n.t('thursday');

    case 5:
      return isShort ? i18n.t('fri') : i18n.t('friday');

    case 6:
      return isShort ? i18n.t('sat') : i18n.t('saturday');

    case 7:
      return isShort ? i18n.t('sun') : i18n.t('sunday');

    default:
      return null;
  }
};

export const getRecurringDays = ({ days, isShort = false } = {}) => {
  if (!days) return '';

  return days?.map((day) => getRecurringDay({ day, isShort })).join(', ');
};

export const getEventString = ({
  name,
  startTime: startDateTime,
  endTime: endDateTime,
  recurringDays,
} = {}) => {
  // NOTE:
  // Symfony only had "time" in these fields... Laravel has "date" and "time".
  // So we'll have to split this up, but still allow just "times" being passed in.
  const isStartDateTime = startDateTime?.includes('-');
  const isEndDateTime = endDateTime?.includes('-');

  const startTime = isStartDateTime
    ? startDateTime?.split(' ')?.[1]
    : startDateTime;
  const endTime = isEndDateTime ? endDateTime?.split(' ')[1] : endDateTime;

  return `${name || ''}${name && startTime && endTime ? ' ' : ''}${
    startTime && endTime
      ? `(${`${startTime.slice(0, -3)} - ${endTime.slice(0, -3)}`})`
      : ''
  }${
    recurringDays
      ? ` - ${getRecurringDays({
          days: recurringDays,
          isShort: true,
        })}`
      : ''
  }`;
};

export const getPaymentGatewayName = ({ id } = {}) => {
  switch (id) {
    case 1:
      return i18n.t('noPayment');

    case 2:
      return 'Sage';

    case 3:
      return 'Three Peaks';

    case 4:
      return 'Netcash';

    case 5:
      return 'GoCardless';

    case 6:
      return 'Stripe';

    case 7:
      return 'Paystack';

    case 8:
      return 'SEPA';

    default:
      return null;
  }
};

export const getUserStatusName = ({ id } = {}) => {
  switch (id) {
    case 1:
      return i18n.t('pending');

    case 2:
      return i18n.t('active');

    case 3:
      return i18n.t('suspended');

    case 4:
      return i18n.t('deactivated');

    case 5:
      return i18n.t('readyForTransfer');

    case 6:
      return i18n.t('onHold');

    default:
      return null;
  }
};

export const getUserTypeString = ({ id }) => {
  switch (id) {
    case 1:
      return i18n.t('superAdmin');

    case 2:
      return i18n.t('owner');

    case 3:
      return i18n.t('trainer');

    case 4:
      return i18n.t('member');

    case 5:
      return i18n.t('admin');

    case 6:
      return i18n.t('locationAdmin');

    case 8:
      return i18n.t('locationCheckIn');

    case 9:
      return i18n.t('leads');

    case 11:
      return i18n.t('user');

    default:
      return '';
  }
};

export const getLabelsAndValuesFromNumber = ({ numberFrom = 0, numberTo }) => {
  if (!numberTo) return [];

  const array = [];

  for (let index = numberFrom; index <= numberTo; index += 1) {
    array.push({ label: `${index}`, value: `${index}` });
  }

  return array;
};

export const canRefreshToken = ({ jwt }) => {
  try {
    const timeNow = Date.now();
    const decoded = jwtDecode(jwt);

    const dateInTwoDays = moment(timeNow).add(2, 'day');
    const dateExpiry = moment(decoded.exp * 1000);

    return moment(dateExpiry).isBefore(dateInTwoDays);
  } catch (error) {
    return false;
  }
};

export const initialiseUser = (user = {}) => ({
  id: user.id || undefined,
  typeId: user.typeId || user.type?.id || undefined,
  memberId: user.memberId || undefined,
  idNumber: user.idNumber || undefined,
  healthProviderId:
    user.healthProviderId || user.healthProvider?.id || undefined,
  name: user.name || undefined,
  surname: user.surname || undefined,
  bio: user.bio || undefined,
  genderId: user.genderId || user.gender?.id || undefined,
  dateOfBirth: user.dateOfBirth || undefined,
  email: user.email || undefined,
  mobile: user.mobile || undefined,
  emergencyContactName: user.emergencyContactName || undefined,
  emergencyContactMobile: user.emergencyContactMobile || undefined,
  image: user.image || undefined,
  landingScreen: user.landingScreen || undefined,
  assignedUserId: user.assignedUserId || user.assignedUser?.id || undefined,
  notes: user.notes || undefined,
  isHighRisk: typeof user.isHighRisk === typeof true ? user.isHighRisk : false,
  locationId: user.locationId || user.location?.id || undefined,
  programmeId: user.programmeId || user.programme?.id || undefined,
  regionId: user.regionId || user.region?.id || undefined,
  locationAccessPrivileges: user.locationAccessPrivileges || [],
  userAccessPrivileges:
    user.userAccessPrivileges || user.accessPrivileges || [],
  password: undefined,
  address: user?.address || undefined,
});

export const canSetMemberModal = ({
  // NOTE: I know this isn't elegant ... but depending on the endpoint userTenant is reutrned differently, sometimes just the ID or TypeId, and not the full userTenant.
  userTenantTypeId,
  userTenant,
  hasAccess,
}) => {
  if (!userTenant && hasAccess && hasAccess.containerMembers) {
    return true;
  }

  if (
    userTenant &&
    (userTenant.typeId === 4 || userTenantTypeId === 4) &&
    hasAccess &&
    hasAccess.containerMembers
  ) {
    return true;
  }

  return false;
};

export const generateYears = ({ start, end, isOptions = false } = {}) => {
  let startYear = start || 2016; // Octiv started in 2016
  let endYear = end || new Date().getFullYear();

  // Add next year if the current month is December
  const currentMonth = new Date().getMonth(); // Month index: 0 for Jan, 11 for Dec
  if (currentMonth === 11) {
    endYear += 1;
  }

  const years = [];
  while (startYear <= endYear) {
    years.push(startYear);
    startYear += 1;
  }

  if (isOptions) {
    return years.reverse().map((year) => ({
      label: year,
      value: year.toString(),
    }));
  }

  return years;
};

export const generateTimeOptions = ({
  minTime,
  maxTime,
  interval = 5,
} = {}) => {
  const options = [];

  const fromHour = minTime ? parseInt(minTime.split(':')[0], 10) : 0;
  const toHour = maxTime ? parseInt(maxTime.split(':')[0], 10) + 1 : 24;

  for (let hour = fromHour; hour < toHour; hour += 1) {
    let fromMinute = 0;
    let toMinute = 60;

    if (hour === fromHour && minTime) {
      fromMinute = parseInt(minTime.split(':')[1], 10);

      if (interval > fromMinute) {
        fromMinute = interval;
      } else {
        let closestInterval = fromMinute + parseInt(interval / 2, 10);
        closestInterval -= closestInterval % interval;

        if (closestInterval > fromMinute) {
          fromMinute = closestInterval;
        } else {
          fromMinute = closestInterval + interval;
        }
      }
    }

    if (hour === toHour - 1 && maxTime) {
      toMinute = parseInt(maxTime.split(':')[1], 10);
    }

    for (let minute = fromMinute; minute < toMinute; minute += interval) {
      options.push({
        label: `${hour.toString().padStart(2, '0')}:${minute
          .toString()
          .padStart(2, '0')}`,
        value: `${hour.toString().padStart(2, '0')}:${minute
          .toString()
          .padStart(2, '0')}:00`,
      });
    }
  }

  return options;
};

export const getBookingsData = ({ allBookings = {} }) => {
  const bookingsArray = [];
  const waitingListArray = [];
  const lateCancellationsArray = [];

  let bookedCountCheckedIn = 0;
  let bookedCountNoShows = 0;

  if (allBookings.bookings) {
    allBookings.bookings.map((item) => {
      if (item?.status?.id === 3) {
        lateCancellationsArray.push(item);
      } else {
        if (item?.checkedInAt) bookedCountCheckedIn += 1;
        if (item?.status?.id === 5) bookedCountNoShows += 1;
        bookingsArray.push(item);
      }

      return null;
    });
  }

  if (allBookings.waitingList) {
    allBookings.waitingList.map((item) => {
      if (item?.status?.id === 3) {
        lateCancellationsArray.push(item);
      } else if (item?.status?.id === 'waiting') {
        waitingListArray.push(item);
      }

      return null;
    });
  }

  return {
    booked: !_.isEmpty(bookingsArray) ? bookingsArray : undefined,
    bookedCount: bookingsArray.length,
    bookedCountCheckedIn,
    bookedCountNoShows,
    waitingList: !_.isEmpty(waitingListArray) ? waitingListArray : undefined,
    waitingListCount: waitingListArray.length,
    lateCancellations: !_.isEmpty(lateCancellationsArray)
      ? lateCancellationsArray
      : undefined,
    lateCancellationsCount: lateCancellationsArray.length,
    bookedWaitingList: [
      ...(!_.isEmpty(bookingsArray) ? bookingsArray : []),
      ...(!_.isEmpty(waitingListArray) ? waitingListArray : []),
    ],
  };
};

export const getMonths = ({ withValue = false } = {}) => [
  { name: i18n.t('jan'), ...(withValue ? { value: 1 } : {}) },
  { name: i18n.t('feb'), ...(withValue ? { value: 2 } : {}) },
  { name: i18n.t('mar'), ...(withValue ? { value: 3 } : {}) },
  { name: i18n.t('apr'), ...(withValue ? { value: 4 } : {}) },
  { name: i18n.t('may'), ...(withValue ? { value: 5 } : {}) },
  { name: i18n.t('jun'), ...(withValue ? { value: 6 } : {}) },
  { name: i18n.t('jul'), ...(withValue ? { value: 7 } : {}) },
  { name: i18n.t('aug'), ...(withValue ? { value: 8 } : {}) },
  { name: i18n.t('sep'), ...(withValue ? { value: 9 } : {}) },
  { name: i18n.t('oct'), ...(withValue ? { value: 10 } : {}) },
  { name: i18n.t('nov'), ...(withValue ? { value: 11 } : {}) },
  { name: i18n.t('dec'), ...(withValue ? { value: 12 } : {}) },
];

export const getWaiverOptions = ({ waiver }) => [
  ...(waiver?.status === 'sent' || waiver?.status === 'signed'
    ? [
        ...(waiver?.isDigital
          ? [{ label: i18n.t('viewWaiver'), value: 'viewWaiver' }]
          : []),
        ...(waiver?.status === 'signed'
          ? [
              {
                label: i18n.t('downloadWaiver'),
                value: 'downloadWaiver',
              },
            ]
          : [
              {
                label: i18n.t('resendWaiver'),
                value: 'sendWaiverOriginal',
              },
            ]),
        {
          label: i18n.t('sendNewWaiver'),
          value: 'sendWaiverLatest',
        },
      ]
    : [
        {
          label: i18n.t('sendWaiver'),
          value: 'sendWaiverLatest',
        },
      ]),
];

export const translateSessionsAvailableText = ({ text }) => {
  const stringText = text?.toString();

  if (stringText?.includes('this month')) {
    return stringText.replace('this month', i18n.t('thisMonth'));
  }

  if (stringText?.includes('this week')) {
    return stringText.replace('this week', i18n.t('thisWeek'));
  }

  return stringText;
};

export const translateInjuryContent = ({ content }) => {
  if (content === 'Marked as "healed"') {
    return i18n.t('markedAsHealed');
  }

  return content;
};

export const sanitizeWorkout = ({ workout, identifyBenchmarks = false }) => {
  if (!workout) {
    return null;
  }

  const {
    id,
    date,
    programme,
    name,
    description,
    warmUp,
    coolDown,
    trainerNotes,
    memberNotes,
    workoutExercises,
  } = workout;

  return {
    id,
    date,
    programme,
    name,
    description,
    warmUp,
    coolDown,
    trainerNotes,
    memberNotes,
    exercises: workoutExercises
      ? workoutExercises.map(({ order, prefix, exercise }) => ({
          order,
          name: exercise.name || undefined,
          prefix: prefix ? prefix.prefix : undefined,
          description: exercise.description || undefined,
          measuringUnit: exercise.measuringUnit || undefined,
          measureId: exercise.measuringUnitId || undefined,
          resourceUrl: exercise.resourceUrl || undefined,
          ...(identifyBenchmarks && exercise.isBenchmark
            ? {
                id: undefined,
                isBenchmark: undefined,
                benchmarkId: exercise.id,
              }
            : {
                id: exercise.id,
                isBenchmark: false,
                benchmarkId: undefined,
              }),
        }))
      : undefined,
  };
};

export const getInvoiceLocationId = (invoice) =>
  invoice?.locationId || invoice?.userLocation?.locationId;

export const transformApiError = (error) => {
  const parsedError = error?.message?.includes('status')
    ? JSON.parse(error.message)
    : error;

  return {
    title: parsedError.message,
    body: _.isObject(parsedError.messageBody)
      ? Object.values(parsedError.messageBody).join(' ')
      : parsedError.messageBody,
  };
};

export const getAddressString = (address) => {
  const { streetAddress, postalCode, city, stateProvinceRegion } =
    address || {};

  if (_.isEmpty(city)) return '';

  return `${streetAddress}, ${postalCode}, ${city}, ${stateProvinceRegion}, ${address?.countryCode}`;
};

export const getUserPackageInfo = ({ userPackage }) => {
  const isLimitedPackage = userPackage.package.typeId === 3;
  const isUnlimitedPackage =
    !isLimitedPackage && userPackage.package.limit === 0;
  const unpaidInvoice = userPackage.invoices?.find(
    (invoice) =>
      invoice.status === 'unpaid' &&
      (invoice.discriminator === 'buyPackageInvoice' ||
        invoice.discriminator === 'topupInvoice')
  );
  const canDisplayUnpaidInvoice = !!unpaidInvoice;
  const canTopUp =
    !isUnlimitedPackage &&
    userPackage.package.topupPrice !== undefined &&
    userPackage.package.topupPrice !== null &&
    !unpaidInvoice;
  const canDisplaySessionsAvailable = !(
    canDisplayUnpaidInvoice && userPackage.sessionsAvailable === 0
  );

  return {
    isLimitedPackage,
    isUnlimitedPackage,
    unpaidInvoice,
    canDisplayUnpaidInvoice,
    canTopUp,
    canDisplaySessionsAvailable,
  };
};

export const filterLocationsWithAccessControl = (locations, all = false) => {
  // Filter locations with "access_control" in their additionalFeatures -> pass in locations from ActiveUserContext
  const filteredLocations = locations
    .filter((location) =>
      location.additionalFeatures?.includes('access_control')
    )
    .map((location) => ({
      label: location.name,
      value: location.id,
    }));

  if (all) {
    return [{ label: 'All' }, ...filteredLocations];
  }

  return filteredLocations;
};

export const appendFile = (formData, key, file) => {
  if (file && typeof file !== 'string') {
    // CREATE / UPDATE
    formData.append(key, file);
  } else if (_.isEmpty(file)) {
    // REMOVE
    formData.append(key, '');
  } else if (!_.isEmpty(file)) {
    // DONT CHANGE
  }
};
