import { Table, Tooltip } from 'octiv-components';
import { getFullName } from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({
  data,
  loggedInUserTypeId,
  onClickAction,
  locationOptions,
  ...props
}) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'name',
        Cell: ({ row: { original } }) => getFullName(original),
      },
      {
        Header: t('location'),
        accessor: 'locationId',
        Cell: ({ row: { original } }) =>
          locationOptions.find((item) => item.value === original.locationId)
            ?.label,
      },
      {
        Header: t('type'),
        accessor: 'provider.name',
      },
      {
        Header: t('actions'),
        accessor: 'id',
        Cell: ({ row: { original } }) => {
          return (
            <Tooltip
              stopPropagation
              options={[
                // TODO: conditionally render suspend or unsuspend with a tenary
                { label: t('delete'), value: 'delete' },
              ]}
              onClick={({ value }) =>
                onClickAction({
                  action: value,
                  id: original.id,
                })
              }
            />
          );
        },
      },
    ],
    [locationOptions, onClickAction, t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
