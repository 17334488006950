import { Formik } from 'formik';
import { Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ locationOptions, onResetPaging, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      validationSchema={Yup.object().shape({
        type: Yup.string().required(t('required')),
        paymentType: Yup.string(),
        startDate: Yup.string().required(t('required')),
        endDate: Yup.string().required(t('required')),
        locationId: Yup.string(),
        sentStatus: Yup.string(),
        search: Yup.string(),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('location')}
                name='locationId'
                options={locationOptions}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('memberType')}
                name='type'
                options={[
                  { label: t('member'), value: 'athleteInvoices' },
                  { label: t('nonMember'), value: 'nonMemberInvoices' },
                  { label: t('lead'), value: 'leadMemberInvoices' },
                ]}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('paymentType')}
                name='paymentType'
                options={[
                  { label: t('all'), value: undefined },
                  {
                    label: t('debitOrder'),
                    value: 'debitOrderInvoices',
                  },
                  { label: t('other'), value: 'otherInvoices' },
                ]}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('status')}
                name='status'
                options={[
                  { label: t('all'), value: undefined },
                  { label: t('unpaid'), value: 'unpaid' },
                  { label: t('paid'), value: 'paid' },
                  { label: t('pending'), value: 'pending' },
                  { label: t('submitted'), value: 'submitted' },
                  { label: t('credited'), value: 'credited' },
                ]}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('sentUnsent')}
                name='sentStatus'
                options={[
                  { label: t('all'), value: undefined },
                  { label: t('sent'), value: 'sent' },
                  { label: t('unsent'), value: 'unsent' },
                ]}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isDate
                label={t('dateFrom')}
                name='startDate'
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isDate
                label={t('dateTo')}
                name='endDate'
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                hasAlternateBackground
                hasSubmitButtonSearch
                buttonSubmitProps={{
                  onClick: () => onResetPaging(),
                }}
                label={t('memberCode')}
                name='search'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
