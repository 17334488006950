import {
  useAccessControlCreateSystemGroupAddUsers,
  useAccessControlCreateSystemGroupRemoveUsers,
  useAccessControlFindSystemGroups,
} from 'octiv-hooks/requests/AccessControl';
import { useAcsEvents } from 'octiv-hooks/useAcsEvents';
import { AcsEvent } from 'octiv-utilities/Constants';
import React, { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import TableAccessGroups from './TableAccessGroups';

export default ({ manageUserId, acsId }) => {
  const [searchParams] = useSearchParams();
  const [allGroups, setAllGroups] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [loadingIds, setLoadingIds] = useState(new Set());

  const locationId = Number(searchParams.get('locationId'));

  const apiFindAllGroups = useAccessControlFindSystemGroups(
    { filter: { id: acsId } },
    { onSuccess: (response) => setAllGroups(response || []) }
  );

  const apiFindUserGroups = useAccessControlFindSystemGroups(
    { filter: { id: acsId, manageUserId } },
    {
      onSuccess: (response) => {
        setUserGroups(response || []);
      },
    }
  );

  const apiRemoveUserFromGroup = useAccessControlCreateSystemGroupRemoveUsers({
    meta: { useOnSuccessToast: true },
  });

  const apiAddUserToGroup = useAccessControlCreateSystemGroupAddUsers({
    meta: { useOnSuccessToast: true },
  });

  const handleUserAddedToGroup = (event) => {
    const { user, group } = event;
    if (user?.id === manageUserId) {
      setLoadingIds((prev) => {
        const newSet = new Set(prev);
        newSet.delete(group.id);
        return newSet;
      });
      setUserGroups((prevGroups) => {
        if (!prevGroups.some((g) => g.id === group.id)) {
          return [...prevGroups, group];
        }
        return prevGroups;
      });
    }
  };

  const handleUserRemovedFromGroup = (event) => {
    const { user, group } = event;
    if (user?.id === manageUserId) {
      setLoadingIds((prev) => {
        const newSet = new Set(prev);
        newSet.delete(group.id);
        return newSet;
      });
      setUserGroups((prevGroups) =>
        prevGroups.filter((g) => g.id !== group.id)
      );
    }
  };

  useAcsEvents(
    locationId,
    [AcsEvent.USER_ADDED_TO_GROUP],
    handleUserAddedToGroup
  );
  useAcsEvents(
    locationId,
    [AcsEvent.USER_REMOVED_FROM_GROUP],
    handleUserRemovedFromGroup
  );

  const tableData = useMemo(() => {
    const userGroupIds = new Set(userGroups.map((group) => group.id));
    return allGroups.map((group) => ({
      ...group,
      isUserInGroup: userGroupIds.has(group.id),
    }));
  }, [allGroups, userGroups]);

  const onClickAction = ({ action, id }) => {
    setLoadingIds((prev) => {
      const newSet = new Set(prev);
      newSet.add(id);
      return newSet;
    });

    switch (action) {
      case 'add':
        apiAddUserToGroup.mutate({
          acsUserIds: manageUserId,
          acsGroupId: id,
          id: acsId,
        });
        break;

      case 'remove':
        apiRemoveUserFromGroup.mutate({
          acsUserIds: manageUserId,
          acsGroupId: id,
          id: acsId,
        });
        break;

      default:
        setLoadingIds((prev) => {
          const newSet = new Set(prev);
          newSet.delete(id);
          return newSet;
        });
        break;
    }
  };

  return (
    <TableAccessGroups
      hasAlternateBackground
      data={tableData}
      isLoading={apiFindAllGroups?.isFetching || apiFindUserGroups.isFetching}
      loadingIds={loadingIds}
      onClickAction={onClickAction}
    />
  );
};
