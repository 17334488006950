import { addListener, removeListener } from 'octiv-utilities/EchoListeners';
import { useEffect } from 'react';

export const useAcsEvents = (locationId, events, callback) => {
  useEffect(() => {
    events.forEach((event) => {
      addListener(event, locationId, callback);
    });

    return () => {
      events.forEach((event) => {
        removeListener(event, locationId, callback);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
