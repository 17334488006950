import { Modal } from 'octiv-components';
import ToggleTabs from 'octiv-components/toggle/tabs/index';
import React from 'react';
import { useTranslation } from 'react-i18next';

import AccessCredentials from './credentials';
import AccessGroups from './groups';

export default ({ manageUserId, onClose, acsId, manageUserName }) => {
  const { t } = useTranslation();

  return (
    <Modal title={`${t('manageUser')}: ${manageUserName}`} onClose={onClose}>
      <ToggleTabs
        hasRadiusTop
        bg='grey6'
        // mt={4}
        options={[
          {
            label: t('accessGroups'),
            children: (
              <AccessGroups acsId={acsId} manageUserId={manageUserId} />
            ),
          },
          {
            label: t('credentials'),
            children: (
              <AccessCredentials acsId={acsId} manageUserId={manageUserId} />
            ),
          },
        ]}
      />
    </Modal>
  );
};
