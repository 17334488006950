import { Formik } from 'formik';
import { Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  isFetchingPackages,
  limitInterFacilityBookings,
  packages,
  locationOptionsAll,
  isFetching,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <Formik
      {...props}
      enableReinitialize
      validationSchema={Yup.object().shape({
        userType: Yup.string().required(t('required')),
        search: Yup.string(),
        userTenantPackageId: Yup.string(),
        ...(!limitInterFacilityBookings && { locationId: Yup.string() }),
      })}
    >
      {({ handleSubmit, values: { userType } }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Field
                canSubmitOnChange
                isSelect
                label={t('userType')}
                name='userType'
                options={[
                  { label: t('member'), value: 'member' },
                  { label: t('staff'), value: 'staff' },
                ]}
              />
            </Col>

            {userType !== 'nonMember' && (
              <>
                <Col md={6}>
                  <Field
                    autoFocus
                    hasSubmitButtonSearch
                    label={t('search')}
                    name='search'
                  />
                </Col>

                {userType !== 'staff' && (
                  <Col md={6}>
                    <Field
                      canSubmitOnChange
                      hasAlternateBackground
                      isSelect
                      isLoading={isFetchingPackages}
                      label={t('package')}
                      name='userTenantPackageId'
                      options={[
                        { label: t('all'), value: undefined },
                        ...(packages?.map((item) => ({
                          label: item.name,
                          value: item.id,
                        })) || []),
                      ]}
                    />
                  </Col>
                )}

                {!limitInterFacilityBookings && (
                  <Col md={6}>
                    <Field
                      canSubmitOnChange
                      hasAlternateBackground
                      isSelect
                      label={t('location')}
                      name='locationId'
                      options={locationOptionsAll}
                    />
                  </Col>
                )}
              </>
            )}
          </Row>
        </form>
      )}
    </Formik>
  );
};
