import * as Sentry from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import firebase from 'firebase/app';
import _ from 'lodash';
import { useQuery } from 'octiv-hooks';
import { useLinkedUsersFind } from 'octiv-hooks/requests/LinkedUsers';
import { useUsersFindMe } from 'octiv-hooks/requests/Users';
import { userStatus } from 'octiv-utilities/Constants';
import { getFullName, urlSearchParam } from 'octiv-utilities/Functions';
import React from 'react';

export const SignedInUserContextInitialState = {
  isSigningIn: true,
  isSignedIn: false,
  isRefetchingSignedInUser: false,

  isSuperAdmin: false,
  isDiscoveryAdmin: false,
  isUser: false,

  id: undefined,
  signedInUserId: undefined,
  idNumber: undefined,
  name: undefined,
  surname: undefined,
  fullName: undefined,
  email: undefined,
  mobile: undefined,
  dateOfBirth: undefined,
  genderId: undefined,
  image: undefined,
  emergencyContactMobile: undefined,
  emergencyContactName: undefined,
  healthProviderId: undefined,
  mergeAccount: undefined,

  userTenants: [],
  invalidUserTenants: [],
  linkedUsers: [],

  refetchSignedInUser: () => {},
  signIn: () => {},
  signOut: () => {},
};

export const SignedInUserContext = React.createContext(
  SignedInUserContextInitialState
);

export const useSignedInUser = () => {
  const signedInUser = React.useContext(SignedInUserContext);

  return signedInUser;
};

export const SignedInUserContextProvider = ({ children }) => {
  const queryClient = useQueryClient();

  const authToken = useQuery('authToken');
  const publicToken = useQuery('publicToken');
  const switchUserToken = useQuery('switchUserToken');

  const [signedInUserContext, setSignedInUserContext] = React.useState(
    SignedInUserContextInitialState
  );

  const signIn = async ({ jwt, mergeAccount }) => {
    try {
      setSignedInUserContext((prev) => ({
        ...SignedInUserContextInitialState,
        refetchSignedInUser: prev.refetchSignedInUser,
        signIn: prev.signIn,
        signOut: prev.signOut,
        mergeAccount,
      }));

      await localStorage.setItem('jwt', jwt);

      queryClient.clear();

      // eslint-disable-next-line no-use-before-define
      usersFindMe.refetch();
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const signOut = async () => {
    try {
      await localStorage.removeItem('jwt');
      await localStorage.removeItem('activeUserTenantId');
      await localStorage.removeItem('canDisplayExtraDetailsInClass');

      // TODO: check if this will sort out the login cache issues
      // await sessionStorage.removeItem('switchUserToken');

      setSignedInUserContext((prev) => ({
        ...SignedInUserContextInitialState,
        isSigningIn: false,
        refetchSignedInUser: prev.refetchSignedInUser,
        signIn: prev.signIn,
        signOut: prev.signOut,
      }));

      queryClient.clear();

      const analytics = firebase.analytics();
      analytics.setUserId(null);
      Sentry.configureScope((scope) => scope.setUser(null));
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const linkedUsersFind = useLinkedUsersFind(
    { paging: { perPage: -1 } },
    { enabled: !!signedInUserContext.id }
  );

  React.useEffect(() => {
    const linkedUsers = linkedUsersFind.data?.data;
    if (linkedUsers) {
      setSignedInUserContext((prev) => ({
        ...prev,
        linkedUsers: linkedUsers
          .map((item) => ({
            id: item.id,
            createdAt: item.createdAt,
            updatedAt: item.updatedAt,
            linkedUser: item?.linkedUser || item.user,
            linkedUserId: item?.linkedUserId,
            // item.userId === signedInUserContext.id
            //   ? item.linkedUser
            //   : item.user,
          }))
          .filter(
            (value, index, self) =>
              index ===
              self.findIndex((item) => item.linkedUserId === value.linkedUserId)
          ),
      }));
    }
  }, [signedInUserContext.id, linkedUsersFind.data?.data]);

  const usersFindMe = useUsersFindMe(null, {
    enabled: false,
    onError: () => {
      signOut();
    },
  });

  React.useEffect(() => {
    setSignedInUserContext((prev) => ({
      ...prev,
      isRefetchingSignedInUser: usersFindMe.isFetching,
    }));
  }, [usersFindMe.isFetching]);

  React.useEffect(() => {
    const user = usersFindMe.data;

    if (user) {
      const isSuperAdmin = user.typeId === 1;
      const isDiscoveryAdmin = user.typeId === 10;

      // TODO: check for staff/member and filter out leads (type 9)
      const [validUserTenants, invalidUserTenants] = _.partition(
        user.userTenants,
        (userTenant) =>
          userTenant.tenant.statusId === 1 &&
          [
            userStatus.ACTIVE,
            userStatus.SUSPENDED,
            userStatus.ON_HOLD,
          ].includes(userTenant.statusId)
      );

      if (signedInUserContext.mergeAccount) {
        /* empty */
      } else if (
        !isSuperAdmin &&
        !isDiscoveryAdmin &&
        validUserTenants.length === 0 &&
        !signedInUserContext.mergeAccount
      ) {
        // TODO: Show no active accounts message to user
        // alert('You currently have no active accounts. Please contact support.');

        signOut();

        return;
      }

      setSignedInUserContext((prev) => {
        if (!prev.id) {
          const analytics = firebase.analytics();
          analytics.setUserId(user.id);
          Sentry.setUser({ id: user.id });
        }

        return {
          ...prev,
          isSigningIn: false,
          isSignedIn: true,
          isRefetchingSignedInUser: false,
          isSuperAdmin,
          isDiscoveryAdmin,

          id: user.id,
          signedInUserId: user.id,
          idNumber: user.idNumber,
          name: user.name,
          surname: user.surname,
          fullName: getFullName({ name: user.name, surname: user.surname }),
          email: user.email,
          mobile: user.mobile,
          dateOfBirth: user.dateOfBirth,
          genderId: user.gender?.id,
          image: user.image,
          emergencyContactMobile: user.emergencyContactMobile,
          emergencyContactName: user.emergencyContactName,
          healthProviderId: user.healthProvider?.id,

          userTenants: signedInUserContext.mergeAccount
            ? user.userTenants
            : validUserTenants,
          invalidUserTenants,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersFindMe.data]);

  React.useEffect(() => {
    setSignedInUserContext((prev) => ({
      ...prev,
      refetchSignedInUser: () => {
        usersFindMe.refetch();
        linkedUsersFind.refetch();
      },
      signIn,
      signOut,
    }));

    (async () => {
      try {
        if (publicToken) {
          setSignedInUserContext((prev) => ({ ...prev, isSigningIn: false }));
        } else {
          let jwt;

          if (authToken) {
            jwt = authToken;

            await localStorage.setItem('jwt', jwt);

            urlSearchParam({ param: 'authToken', isDelete: true });
          } else {
            jwt = await localStorage.getItem('jwt');
          }

          if (switchUserToken) {
            await sessionStorage.setItem('switchUserToken', switchUserToken);

            urlSearchParam({ param: 'switchUserToken', isDelete: true });
          }

          if (jwt || switchUserToken) {
            usersFindMe.refetch();
          } else {
            setSignedInUserContext((prev) => ({ ...prev, isSigningIn: false }));
          }
        }
      } catch (error) {
        signOut();
        Sentry.captureException(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SignedInUserContext.Provider value={signedInUserContext}>
      {children}
    </SignedInUserContext.Provider>
  );
};
