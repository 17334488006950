import { Box, Button, ConfirmationDialog, Container } from 'octiv-components';
import {
  useAccessControlCreateAccount,
  useAccessControlDeleteAccount,
  useAccessControlFindAccounts,
} from 'octiv-hooks/requests/AccessControl';
import { useAcsEvents } from 'octiv-hooks/useAcsEvents';
import { AcsEvent, acsPartners } from 'octiv-utilities/Constants';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormQuery from '../FormQuery';
import TableAccessAccounts from './TableAccessAccounts';

export default ({ setQuery, locationOptionsAll, query }) => {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const [credentialToDelete, setCredentialToDelete] = useState(null);

  const apiFind = useAccessControlFindAccounts({
    filter: query,
  });

  useAcsEvents(query?.locationId, [AcsEvent.SYNC_COMPLETE], () => {
    apiFind.refetch();
  });

  const apiCreate = useAccessControlCreateAccount({
    onSuccess: (res) => {
      window.open(res.url, '_blank');
    },
  });

  const apiDelete = useAccessControlDeleteAccount({});

  const handleActionClick = ({ action, id }) => {
    switch (action) {
      case 'delete':
        setCredentialToDelete(id);
        setOpenDialog(true);
        break;
      default:
        break;
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDeleteCredential = () => {
    if (credentialToDelete) {
      apiDelete.mutate({
        id: credentialToDelete,
      });
    }
    setOpenDialog(false);
  };

  return (
    <Container
      appBarProps={{
        title: t('accounts'),
        breadcrumbs: [t('accessControl')],
        children: (
          <Box ml='auto'>
            <Box isFlex>
              <Button
                hasBorder
                mr={2}
                text={t('addAccount')}
                onClick={() =>
                  apiCreate.mutate({
                    locationId: query.locationId,
                    providerId: acsPartners.SEAM,
                  })
                }
              />{' '}
            </Box>
          </Box>
        ),
      }}
    >
      <ConfirmationDialog
        isDanger
        confirmText={t('delete')}
        message={t('deleteAcsAccountConfirmation')}
        open={openDialog}
        securityPrompt={t('securityPhraseDeleteAccount')}
        title={t('securityPhraseDeleteAccount')}
        onCancel={handleDialogClose}
        onConfirm={handleDeleteCredential}
      />
      <FormQuery
        initialValues={query}
        locationOptions={locationOptionsAll}
        onSubmit={setQuery}
      />
      <Box height={4} />
      <TableAccessAccounts
        data={apiFind.data?.data}
        locationOptions={locationOptionsAll}
        onClickAction={handleActionClick}
      />
    </Container>
  );
};
