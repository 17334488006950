import { Box, Icon, Table, Text, Tooltip } from 'octiv-components';
import { AccessContext } from 'octiv-context';
import { useMemberModal } from 'octiv-context/MemberModal';
import {
  canSetMemberModal,
  getDateReadableDayMonthYear,
} from 'octiv-utilities/Functions';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({
  removingUsers,
  data,
  onClickAction,
  loggedInUserTypeId,
  ...props
}) => {
  const { t } = useTranslation();

  const hasAccess = useContext(AccessContext);
  const { setMemberModal } = useMemberModal();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'name',
        sortBy: 'name',
        Cell: ({
          row: {
            original: { name, tenantUserId, tenantUserType }, // Include tenantTypeId
          },
        }) => {
          const isStaffType =
            tenantUserType?.id && [2, 3, 5, 6, 8].includes(tenantUserType.id);

          return (
            <Text
              as='span'
              onClick={
                canSetMemberModal({
                  userTenant: true,
                  userTenantTypeId: tenantUserType?.id,
                  hasAccess,
                })
                  ? () => setMemberModal({ userTenantId: tenantUserId })
                  : null
              }
            >
              {isStaffType ? `${name} (${t('staff')})` : name}
            </Text>
          );
        },
      },
      {
        Header: t('status'),
        accessor: 'isSuspended',
        Cell: ({ row: { original } }) =>
          removingUsers.includes(original.id)
            ? t('removing')
            : original.warnings.some(({ message }) =>
                message?.includes('deleted')
              )
            ? t('removing')
            : !original.isLatestDesiredStateSyncedWithProvider
            ? t('syncing')
            : original.isSuspended
            ? t('suspended')
            : t('active'),
      },
      {
        Header: t('created'),
        accessor: 'createdAt',
        sortBy: 'createdAt',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
      {
        Header: t('linked'),
        Cell: ({
          row: {
            original: { seamIdentityId },
          },
        }) => (
          <Box isFlex>
            <Icon
              color={seamIdentityId ? 'success' : 'grey3'}
              name='check_circle'
              px={1}
              tooltip={seamIdentityId ? t('linked') : t('notLinked')}
            />
          </Box>
        ),
      },
      {
        Header: t('actions'),
        accessor: 'id',
        Cell: ({ row: { original } }) => {
          const isRemoving = removingUsers.includes(original.id); // Check if in "removing" state
          const baseOptions = [
            { label: t('delete'), value: 'delete', disabled: isRemoving },
          ];

          const additionalOptions = original.seamIdentityId
            ? [
                {
                  label: t('manageUser'),
                  value: 'manage',
                  disabled: isRemoving,
                },
                original?.isSuspended
                  ? {
                      label: t('unsuspend'),
                      value: 'unsuspend',
                      disabled: isRemoving,
                    }
                  : {
                      label: t('suspend'),
                      value: 'suspend',
                      disabled: isRemoving,
                    },
              ]
            : [
                {
                  label: t('linkOctivUser'),
                  value: 'link',
                  disabled: isRemoving,
                },
              ];

          const options = [...additionalOptions, ...baseOptions];
          return (
            !isRemoving && (
              <Tooltip
                stopPropagation
                options={options}
                onClick={({ value }) =>
                  onClickAction({
                    action: value,
                    id: original.id,
                    name: original.name,
                  })
                }
              />
            )
          );
        },
      },
    ],
    [onClickAction, removingUsers, hasAccess, setMemberModal, t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
