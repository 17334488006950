import { Formik } from 'formik';
import { Button, Field, Text } from 'octiv-components';
import { isDevelopment } from 'octiv-utilities/Constants';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

const AuthForm = ({
  isLoading,
  onClickForgotPassword,
  onClickPrivacyPolicy,
  onClickTermsOfUse,
  ...props
}) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Formik
      {...props}
      initialValues={{
        email: isDevelopment
          ? process.env.REACT_APP_AUTH_LOGIN_EMAIL
          : undefined,
        password: isDevelopment
          ? process.env.REACT_APP_AUTH_LOGIN_PASSWORD
          : undefined,
        acceptPrivacyPolicy: isDevelopment,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .trim()
          .email(t('invalidEmail'))
          .required(t('required')),
        password: Yup.string().required(t('required')),
        acceptPrivacyPolicy: Yup.bool()
          .oneOf([true], t('privacyPolicyTermsMustBeAccepted'))
          .required(t('required')),
      })}
    >
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <Field
            autoComplete
            boxContainerProps={{ mb: 4 }}
            label={t('emailAddress')}
            name='email'
            type='email'
          />

          <Field
            autoComplete
            boxContainerProps={{ mb: 4 }}
            iconRightProps={{
              name: showPassword ? 'visibility_off' : 'visibility',
            }}
            label={t('password')}
            name='password'
            type={showPassword ? 'text' : 'password'}
            onClickIconRight={() => setShowPassword(!showPassword)}
          />

          <Field
            isCheckbox
            boxContainerProps={{ mb: 4 }}
            name='acceptPrivacyPolicy'
            options={[{ label: '', value: true }]}
            renderRight={() => (
              <Text>
                {`${t('iAcceptThe')} `}
                <Text as='span' color='primary' onClick={onClickPrivacyPolicy}>
                  {t('privacyPolicy')}
                </Text>
                {` & `}
                <Text as='span' color='primary' onClick={onClickTermsOfUse}>
                  {t('termsOfUse')}
                </Text>
              </Text>
            )}
          />

          <Button
            isFullWidth
            isLoading={isLoading}
            text={t('login')}
            type='submit'
          />

          <Text
            pt={4}
            textAlign='center'
            onClick={() => onClickForgotPassword(values)}
          >
            {t('forgotPassword')}
          </Text>
        </form>
      )}
    </Formik>
  );
};

export default AuthForm;
