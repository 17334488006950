import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import { getDateYearMonthDay } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  isCurrent,
  locationOptions,
  initialValues = {},
  isCreateFlow,
  isLoading,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        locationId: initialValues.locationId || undefined,
        startDate:
          getDateYearMonthDay({ date: initialValues.startingAt }) || undefined,
        endDate:
          getDateYearMonthDay({ date: initialValues.endingAt }) || undefined,
        file: initialValues.file || undefined,
      }}
      validationSchema={Yup.object().shape({
        locationId: Yup.number().required(t('required')),
        startDate: Yup.string().required(t('required')),
        endDate: Yup.string(),
        file: Yup.string(),
      })}
    >
      {({ handleSubmit, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field
                isSelect
                isDisabled={!isCreateFlow}
                label={t('location')}
                name='locationId'
                options={locationOptions}
              />
            </Col>

            <Col md={6}>
              <Field
                isDate
                isDisabled={!isCreateFlow && !isCurrent}
                label={t('startDate')}
                name='startDate'
              />
            </Col>

            <Col md={6}>
              <Field
                isDate
                isDisabled={!isCreateFlow && !isCurrent}
                label={t('endDate')}
                name='endDate'
              />
            </Col>

            <Col>
              <Field isDrop label={t('document')} name='file' />
            </Col>

            <Col>
              <Button
                isDisabled={!dirty}
                isLoading={isLoading}
                text={t('submit')}
                type='submit'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
